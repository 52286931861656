import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">


<path d="M0 6400 l0 -6400 6400 0 6400 0 0 6400 0 6400 -6400 0 -6400 0 0
-6400z m6871 5070 c81 -6 169 -14 195 -19 27 -5 99 -17 159 -26 283 -43 662
-157 960 -288 230 -101 648 -334 695 -388 3 -3 28 -20 55 -39 46 -31 84 -60
200 -153 146 -118 360 -323 482 -462 34 -38 73 -83 88 -100 14 -16 47 -57 73
-90 25 -33 49 -62 52 -65 14 -11 200 -291 260 -390 74 -123 202 -377 255 -505
20 -49 41 -101 47 -115 53 -128 168 -513 168 -561 0 -15 6 -29 13 -32 9 -3 17
-31 21 -74 4 -37 11 -93 16 -123 5 -30 19 -140 30 -245 12 -104 28 -207 37
-227 13 -30 14 -59 9 -150 -4 -62 -6 -133 -6 -158 1 -25 -3 -79 -9 -120 -6
-41 -17 -147 -26 -235 -20 -208 -62 -438 -120 -658 -25 -98 -124 -389 -150
-445 -14 -29 -25 -55 -25 -59 0 -8 -93 -217 -100 -223 -3 -3 -25 -45 -49 -95
-49 -98 -128 -234 -213 -366 -50 -77 -227 -319 -252 -345 -6 -6 -29 -33 -51
-60 -113 -139 -360 -386 -499 -499 -27 -22 -54 -45 -60 -51 -27 -26 -230 -172
-331 -238 -401 -264 -890 -473 -1335 -570 -41 -9 -91 -21 -110 -26 -48 -12
-266 -47 -375 -60 -346 -41 -829 -25 -1175 40 -246 46 -335 68 -585 147 -287
90 -614 244 -900 421 -461 287 -882 689 -1201 1147 -81 117 -169 257 -210 335
-16 30 -32 57 -35 60 -8 6 -127 253 -169 350 -112 258 -217 612 -266 893 -49
286 -58 394 -58 732 0 321 9 456 48 680 7 44 11 111 8 148 -4 63 -3 69 16 74
14 3 23 16 27 37 29 148 158 544 225 691 87 191 116 251 160 331 59 105 125
220 130 224 3 3 18 25 32 50 60 104 236 341 357 480 123 142 434 443 515 499
12 8 23 17 26 21 32 37 417 310 437 310 3 0 17 8 31 19 25 17 89 52 237 130
33 18 101 50 150 73 50 22 109 49 133 59 136 62 426 157 637 209 422 103 808
132 1326 100z m-1311 -8419 c108 -12 125 -14 150 -23 8 -3 94 -16 190 -28 96
-12 185 -26 198 -31 13 -5 37 -9 55 -9 31 0 31 -1 -26 -36 -31 -21 -57 -42
-57 -48 1 -27 18 -38 49 -31 l31 7 0 -106 0 -106 35 0 35 0 2 123 c1 67 2 139
3 160 l0 38 50 -3 50 -3 -4 -111 c-3 -96 0 -118 17 -158 23 -51 79 -108 132
-134 46 -22 213 -24 256 -2 60 30 104 72 130 125 25 51 26 61 23 172 l-4 118
43 -1 c23 0 42 2 42 6 0 4 21 10 48 13 99 11 269 37 317 48 107 24 276 29
1338 35 865 5 1100 4 1112 -6 13 -10 15 -55 15 -279 0 -194 -3 -270 -12 -279
-9 -9 -732 -12 -3193 -12 -1749 0 -3186 4 -3193 9 -19 11 -18 551 1 564 18 12
2040 1 2167 -12z m1116 -6 c140 -43 209 -210 141 -343 -57 -110 -200 -163
-311 -114 -25 12 -46 25 -46 31 0 18 37 21 73 6 107 -45 242 25 266 137 17 78
-2 141 -58 191 -42 37 -71 47 -138 47 -135 0 -223 -125 -180 -258 9 -26 14
-58 11 -69 -16 -60 -74 52 -74 142 0 68 26 125 81 177 66 62 145 80 235 53z
m-6 -113 c48 -23 88 -71 92 -111 3 -24 1 -24 -57 -22 -85 3 -105 19 -105 80 0
27 -3 57 -6 65 -8 21 17 17 76 -12z m-115 -82 c-6 -33 -3 -40 30 -68 37 -33
63 -40 81 -22 12 12 74 -7 74 -23 0 -6 -7 -19 -17 -29 -42 -46 -153 -1 -211
87 -38 57 -40 83 -10 115 l21 23 19 -23 c14 -18 18 -33 13 -60z m21 -186 c-1
0 -22 1 -48 4 -45 4 -46 5 -50 41 l-3 37 51 -40 c28 -23 50 -41 50 -42z"/>
<path d="M6520 11324 c-8 -1 -59 -6 -113 -9 -60 -4 -100 -11 -102 -18 -2 -7
-7 -79 -10 -160 -4 -82 -11 -145 -15 -140 -8 8 -25 80 -40 169 -5 28 -14 70
-20 93 l-11 43 -77 -6 c-42 -4 -101 -9 -131 -12 l-54 -6 7 -51 c3 -29 13 -149
21 -267 18 -261 24 -342 31 -398 l5 -43 82 7 c45 3 83 8 85 9 2 2 -4 101 -12
221 -9 119 -14 219 -11 222 3 3 5 -3 5 -13 0 -10 7 -46 15 -79 25 -105 53
-229 65 -288 11 -57 12 -58 44 -58 19 0 47 3 63 6 l30 6 7 91 c3 51 9 153 13
227 9 166 17 136 34 -127 l13 -183 89 0 89 0 -5 53 c-4 28 -9 93 -12 142 -11
167 -36 490 -41 533 -5 40 -9 44 -44 36z"/>
<path d="M6786 11298 c-3 -7 -7 -40 -10 -73 -3 -33 -17 -154 -31 -270 -43
-348 -46 -388 -35 -395 20 -12 318 -43 355 -37 44 7 73 30 91 74 16 37 64 416
64 501 0 78 -13 112 -54 140 -44 30 -77 38 -226 57 -150 18 -150 18 -154 3z
m233 -167 c5 -9 5 -43 0 -76 -5 -33 -13 -107 -19 -165 -20 -189 -36 -241 -73
-227 -11 4 -12 20 -7 69 49 438 48 432 75 422 9 -4 19 -14 24 -23z"/>
<path d="M5518 11177 c-64 -18 -119 -35 -122 -38 -3 -3 -2 -39 4 -80 53 -403
80 -618 79 -639 0 -20 -5 -16 -25 25 -13 28 -25 54 -27 58 -1 5 -31 -3 -67
-17 -35 -14 -65 -24 -67 -22 -5 5 -20 148 -32 322 -9 111 -16 154 -37 205 -14
35 -30 68 -34 73 -8 8 -91 -23 -217 -80 -28 -13 -56 -24 -60 -24 -5 0 -17 -6
-27 -13 -17 -12 -17 -16 9 -78 15 -35 29 -66 31 -68 1 -2 28 7 58 20 31 12 58
20 62 17 5 -6 29 -212 50 -443 4 -42 16 -89 30 -117 21 -42 27 -47 47 -39 54
21 286 120 303 130 11 6 61 22 112 35 52 14 95 26 97 28 1 2 -2 31 -8 66 -5
34 -7 65 -5 68 3 3 18 6 34 7 25 2 29 -3 47 -56 23 -66 9 -63 145 -31 39 9 72
21 72 25 0 7 -70 171 -170 399 -16 36 -51 117 -78 180 -36 85 -54 116 -68 117
-11 1 -72 -13 -136 -30z m160 -430 c11 -25 10 -28 -15 -38 -14 -5 -30 -6 -34
-2 -7 8 -39 217 -38 250 0 10 17 -27 38 -82 21 -55 43 -113 49 -128z"/>
<path d="M7457 11143 c-14 -32 -33 -78 -43 -103 -9 -25 -21 -49 -25 -55 -4 -5
-16 -32 -27 -60 -28 -73 -160 -377 -172 -398 -15 -25 -5 -35 38 -43 20 -4 60
-15 89 -25 60 -20 62 -19 90 56 10 28 22 49 26 48 4 -2 19 -3 33 -3 25 0 26
-2 20 -37 -4 -21 -10 -54 -13 -73 l-5 -36 93 -26 c52 -15 96 -25 98 -22 7 7
105 754 99 759 -5 5 -249 75 -263 75 -7 0 -24 -26 -38 -57z m94 -318 c-26
-138 -32 -150 -70 -126 -12 8 -6 31 35 135 27 69 52 126 55 126 3 0 -6 -61
-20 -135z"/>
<path d="M7946 10994 c-14 -31 -26 -63 -26 -71 0 -8 20 -23 45 -34 25 -11 45
-24 45 -30 0 -6 -9 -29 -19 -52 -10 -23 -64 -142 -120 -265 -56 -123 -101
-226 -101 -228 0 -11 173 -85 181 -77 4 4 28 53 52 108 124 277 189 421 196
428 5 5 27 -1 49 -12 23 -12 45 -21 51 -21 8 0 75 130 69 135 -2 1 -46 21 -98
45 -52 23 -131 59 -175 80 -127 59 -119 59 -149 -6z"/>
<path d="M4443 10717 c-40 -29 -73 -58 -73 -66 0 -17 63 -132 145 -266 34 -55
67 -112 74 -125 10 -22 9 -21 -13 5 -132 156 -283 324 -292 324 -13 1 -164
-107 -164 -117 0 -4 21 -43 46 -87 25 -44 59 -105 76 -135 17 -30 38 -66 48
-80 9 -14 27 -43 39 -65 l23 -40 -28 30 c-15 17 -83 94 -152 173 -69 78 -128
142 -132 142 -13 0 -145 -100 -145 -109 0 -5 23 -33 50 -63 28 -29 142 -151
254 -270 112 -120 208 -218 215 -218 11 0 84 51 159 112 l37 30 -41 77 c-23
42 -51 94 -64 116 -84 151 -88 150 54 10 73 -71 134 -132 136 -134 4 -3 131
83 178 123 l28 23 -57 99 c-32 54 -69 119 -83 144 -14 25 -29 47 -33 48 -4 2
-8 8 -8 13 0 5 -34 69 -77 142 -42 73 -87 151 -100 175 -14 23 -25 42 -26 42
-1 -1 -35 -24 -74 -53z"/>
<path d="M8538 10756 c-7 -7 -58 -66 -113 -131 -55 -65 -120 -141 -145 -169
-25 -28 -63 -74 -85 -101 -22 -27 -54 -66 -72 -85 -17 -19 -41 -47 -52 -62
l-21 -26 80 -51 c45 -28 86 -51 93 -51 7 1 27 21 45 45 35 48 47 53 78 30 19
-14 18 -15 -8 -65 -16 -27 -28 -56 -28 -63 0 -13 138 -107 157 -107 11 0 17
12 74 142 21 48 51 115 67 150 16 35 48 106 72 158 23 52 57 127 75 165 18 39
31 75 29 80 -3 6 -17 17 -32 26 -15 9 -66 41 -114 72 -71 46 -89 54 -100 43z
m-39 -369 c-33 -64 -66 -117 -73 -117 -20 0 -39 27 -30 42 15 26 157 198 160
195 2 -2 -23 -56 -57 -120z"/>
<path d="M4988 10319 c-54 -27 -71 -50 -48 -64 5 -3 18 -26 29 -50 14 -31 26
-45 39 -45 25 0 112 48 112 62 0 14 -61 128 -69 128 -3 -1 -32 -14 -63 -31z"/>
<path d="M9110 10224 c-42 -18 -331 -301 -371 -363 -54 -83 -36 -172 51 -262
53 -55 107 -80 171 -81 58 0 93 18 166 86 l58 55 -69 70 -69 70 -64 -59 c-35
-33 -69 -60 -76 -60 -15 0 -37 20 -37 34 0 11 303 319 343 348 22 16 31 17 42
8 26 -22 16 -44 -50 -110 -36 -35 -65 -69 -65 -75 0 -6 29 -40 65 -75 l65 -64
65 64 c82 81 110 135 101 192 -10 60 -33 104 -83 155 -76 77 -164 101 -243 67z"/>
<path d="M6499 10196 c-2 -2 -56 -6 -119 -9 -63 -4 -128 -9 -145 -12 -16 -2
-82 -12 -145 -20 -167 -24 -431 -91 -565 -145 -11 -4 -65 -27 -120 -49 -55
-23 -106 -41 -112 -41 -7 0 -13 -3 -13 -8 0 -4 -27 -18 -60 -30 -33 -13 -60
-28 -60 -33 0 -5 -9 -9 -20 -9 -11 0 -20 -3 -20 -8 0 -4 -17 -15 -37 -25 -47
-21 -135 -74 -229 -135 -10 -7 -34 -22 -51 -33 -18 -12 -33 -25 -33 -30 0 -5
-7 -9 -15 -9 -8 0 -15 -4 -15 -8 0 -4 -12 -15 -27 -24 -16 -9 -39 -27 -53 -40
-14 -13 -53 -45 -87 -71 -34 -26 -70 -58 -81 -72 -11 -14 -22 -25 -25 -25 -7
0 -147 -141 -147 -149 0 -3 -9 -12 -19 -18 -23 -15 -221 -258 -221 -272 0 -5
-3 -11 -7 -13 -9 -4 -73 -85 -73 -92 0 -6 -63 -109 -71 -116 -3 -3 -20 -32
-38 -65 -18 -33 -37 -64 -42 -69 -5 -6 -9 -18 -9 -28 0 -10 -4 -18 -9 -18 -5
0 -20 -27 -33 -60 -12 -33 -26 -60 -30 -60 -5 0 -8 -6 -8 -14 0 -12 -46 -123
-71 -168 -5 -10 -9 -23 -9 -30 0 -7 -9 -36 -20 -65 -54 -141 -68 -193 -112
-388 -23 -103 -31 -164 -53 -395 -20 -198 -15 -430 14 -660 15 -122 79 -424
101 -475 5 -11 14 -38 20 -60 19 -68 51 -150 117 -305 24 -56 155 -315 162
-320 3 -3 15 -21 25 -40 11 -19 22 -37 25 -40 10 -9 31 -44 37 -62 4 -10 10
-18 14 -18 4 0 15 -18 26 -40 10 -22 25 -40 32 -40 6 0 12 -5 12 -11 0 -10 44
-75 64 -94 6 -5 31 -35 55 -65 25 -30 62 -72 83 -93 21 -21 38 -40 38 -42 0
-8 143 -145 151 -145 5 0 9 -7 9 -15 0 -8 4 -15 8 -15 4 0 27 -18 50 -40 22
-22 43 -40 46 -40 3 0 16 -10 29 -22 38 -37 108 -92 133 -106 13 -7 24 -17 24
-22 0 -6 5 -10 11 -10 6 0 25 -11 43 -25 18 -14 36 -25 40 -25 4 0 28 -16 53
-35 25 -19 51 -35 58 -35 7 0 18 -6 24 -14 6 -7 44 -30 84 -50 40 -20 79 -41
87 -46 47 -30 302 -140 325 -140 9 0 23 -7 31 -15 9 -8 26 -15 40 -15 13 0 32
-4 42 -10 17 -10 169 -56 232 -70 269 -60 415 -80 640 -86 300 -8 558 16 820
78 89 20 305 85 347 104 17 8 38 14 46 14 9 0 18 4 22 10 3 5 31 17 63 27 31
10 59 20 62 24 3 3 26 14 52 24 26 10 53 24 59 31 6 8 20 14 30 14 11 0 19 4
19 8 0 4 17 14 38 21 20 8 39 17 42 20 7 8 75 46 122 68 21 10 38 24 38 31 0
6 7 12 15 12 9 0 40 18 70 40 30 22 59 40 65 40 5 0 10 3 10 8 0 4 18 18 40
32 22 14 40 28 40 32 0 5 5 8 10 8 6 0 18 8 28 18 10 9 38 33 62 52 68 53 339
327 394 398 27 34 53 62 57 62 5 0 9 7 9 15 0 8 4 15 8 15 4 0 16 13 25 30 10
16 23 35 30 42 7 7 21 29 31 48 11 19 30 46 43 60 13 14 23 33 23 42 0 10 6
18 13 18 6 0 20 17 30 38 22 47 60 115 68 122 3 3 12 22 20 43 7 20 17 37 21
37 4 0 8 8 8 19 0 10 7 24 15 31 8 6 17 22 21 33 11 40 29 77 36 77 5 0 8 9 8
20 0 11 11 43 25 72 14 28 25 57 25 62 0 6 7 25 16 41 8 17 21 53 29 80 7 28
24 86 38 130 28 93 40 146 62 270 9 50 23 125 31 168 18 91 27 524 15 677 -10
120 -48 361 -66 420 -7 25 -15 56 -18 70 -8 44 -57 204 -67 222 -6 10 -10 27
-10 38 0 11 -9 37 -20 57 -11 21 -20 43 -20 48 0 15 -98 240 -121 279 -10 17
-32 60 -50 96 -18 36 -40 76 -49 90 -107 160 -140 211 -140 220 0 5 -4 10 -10
10 -5 0 -16 12 -24 28 -8 15 -31 44 -50 65 -20 21 -36 41 -36 46 0 4 -27 35
-60 70 -33 35 -60 67 -60 72 0 5 -7 9 -15 9 -8 0 -15 4 -15 9 0 17 -173 184
-280 271 -30 25 -59 50 -65 56 -19 20 -84 64 -94 64 -6 0 -11 4 -11 9 0 5 -17
20 -37 32 -21 13 -39 27 -41 31 -2 4 -8 8 -13 8 -10 0 -103 62 -107 73 -2 4
-10 7 -18 7 -11 0 -75 34 -134 71 -8 5 -23 12 -32 15 -10 4 -18 10 -18 15 0 5
-6 9 -13 9 -7 0 -40 14 -75 31 -34 16 -92 41 -127 54 -36 14 -73 29 -82 35
-48 27 -297 100 -458 134 -44 9 -96 21 -115 26 -98 25 -612 63 -631 46z m250
-166 c207 -12 466 -53 601 -95 25 -7 79 -23 120 -36 170 -50 547 -216 601
-265 8 -8 19 -14 23 -14 7 0 121 -71 141 -88 6 -4 33 -23 60 -42 28 -19 55
-39 61 -45 20 -17 48 -39 79 -62 17 -12 58 -45 90 -75 466 -417 768 -913 921
-1510 89 -349 107 -797 48 -1168 -32 -200 -109 -476 -184 -655 -44 -105 -91
-207 -100 -215 -3 -3 -23 -40 -44 -83 -46 -92 -186 -304 -265 -402 -31 -38
-65 -81 -76 -94 -51 -63 -266 -278 -330 -329 -115 -93 -117 -96 -117 -136 0
-28 -6 -40 -26 -54 -51 -35 -58 -34 -115 21 -35 35 -87 69 -158 105 -57 28
-110 52 -116 52 -6 0 -16 7 -23 15 -7 8 -16 13 -20 10 -4 -3 -10 2 -14 10 -3
8 -15 15 -27 15 -13 0 -31 7 -41 17 -25 23 -32 11 -22 -39 l9 -43 57 -6 c105
-11 355 -158 357 -209 1 -9 -24 -27 -60 -45 -59 -29 -61 -29 -72 -10 -6 11
-13 29 -14 40 -5 31 -32 65 -47 59 -7 -3 -20 6 -29 19 -19 29 -57 43 -57 21 0
-28 27 -79 63 -119 45 -49 46 -69 7 -84 -16 -6 -43 -16 -60 -22 -16 -6 -37 -8
-46 -5 -12 4 -15 2 -10 -9 3 -10 -1 -15 -14 -15 -10 0 -31 -6 -47 -14 -60 -30
-176 -76 -193 -76 -10 0 -31 -6 -47 -14 -15 -8 -64 -24 -108 -36 -44 -12 -97
-28 -118 -36 -40 -15 -76 -19 -42 -4 17 7 15 9 -12 9 -18 1 -33 6 -33 11 0 6
-6 10 -13 10 -14 0 -40 27 -50 53 -8 21 20 37 66 37 18 0 37 3 41 7 11 12 110
37 184 47 12 2 31 11 42 21 10 9 24 14 31 9 8 -4 10 -3 5 4 -11 18 25 14 46
-5 10 -9 21 -13 26 -9 4 4 -2 14 -13 23 -20 15 -20 15 0 9 25 -7 54 9 40 22
-6 5 -26 18 -45 29 -37 21 -69 22 -195 5 -75 -10 -105 -19 -105 -32 0 -5 -36
-9 -80 -9 -70 -1 -81 2 -90 19 -15 28 -7 36 41 43 54 7 91 35 103 78 6 19 14
37 18 40 5 3 -2 13 -16 23 -14 9 -26 22 -26 29 0 6 -14 25 -30 41 -17 16 -30
34 -30 41 0 6 -8 20 -17 31 -10 11 -29 35 -42 54 -14 19 -30 39 -38 43 -7 4
-13 15 -13 23 0 9 -9 29 -20 44 -12 17 -16 31 -11 35 6 3 19 -12 30 -34 12
-22 25 -42 31 -46 6 -3 30 -37 55 -75 45 -69 77 -103 60 -64 -4 10 -8 27 -9
36 0 9 -3 15 -6 13 -8 -5 -25 16 -61 70 -28 43 -79 132 -79 140 0 3 0 12 0 21
0 8 -4 13 -9 10 -13 -8 -24 25 -12 37 6 6 18 -6 33 -32 12 -22 26 -41 29 -41
9 0 -11 81 -23 96 -11 15 -1 41 19 48 9 4 13 13 9 22 -4 9 -2 22 4 29 18 22
33 126 19 139 -8 8 -13 8 -16 -2 -3 -7 -16 -17 -29 -22 l-24 -9 21 -35 c20
-34 20 -37 4 -71 -10 -19 -20 -35 -24 -36 -3 0 -24 -3 -46 -6 -65 -9 -109 -5
-121 11 -6 9 -18 16 -27 16 -9 0 -19 5 -22 10 -3 6 -19 10 -35 10 -16 0 -32 5
-35 10 -3 6 -15 10 -26 10 -10 0 -19 5 -19 10 0 6 7 10 15 10 22 0 18 18 -6
31 -18 9 -24 8 -31 -4 -15 -28 -115 -11 -200 34 -10 5 -27 9 -39 9 -16 0 -19
4 -14 21 6 18 4 20 -17 14 -12 -4 -26 -12 -29 -17 -3 -5 -9 -3 -12 5 -3 8 -19
18 -36 22 -24 5 -31 12 -31 31 0 17 5 24 20 24 27 0 90 -27 90 -38 0 -5 15 -9
33 -9 17 0 77 -12 132 -27 152 -40 400 -56 400 -26 0 8 -15 22 -32 31 -18 9
-38 21 -45 26 -7 6 -28 11 -47 11 -19 0 -37 6 -42 13 -4 6 -14 9 -23 6 -23 -9
-64 32 -45 44 17 11 -32 2 -73 -13 -22 -8 -46 -5 -120 18 -51 15 -99 32 -106
37 -14 11 -66 19 -124 20 -21 1 -33 4 -27 8 6 3 -8 9 -31 13 -22 3 -43 13 -46
21 -11 28 5 63 32 69 26 7 133 -9 169 -24 31 -14 100 -30 230 -55 66 -13 146
-30 178 -39 68 -19 109 -20 124 -5 20 20 -10 38 -87 54 -39 8 -73 15 -76 15
-2 0 -4 6 -4 13 0 20 -59 40 -129 42 -74 3 -220 52 -198 67 7 5 -5 6 -25 2
-21 -3 -46 -3 -55 1 -10 4 -31 7 -46 6 -53 -5 -106 10 -112 30 -9 26 -17 24
-40 -11 -22 -33 -45 -37 -107 -19 -37 10 -38 12 -38 54 0 35 5 47 21 56 34 18
248 2 344 -26 44 -12 146 -39 227 -58 80 -20 176 -50 213 -68 l67 -31 56 17
c77 24 88 43 52 85 -11 13 -20 29 -20 37 0 17 -10 17 -24 -3 -22 -29 -82 -34
-177 -14 -92 20 -137 38 -159 65 -12 14 -14 13 -25 -7 -10 -20 -18 -23 -65
-21 -62 3 -91 23 -74 50 20 32 73 42 185 36 58 -3 122 -3 142 1 l37 7 -1 103
c0 103 -1 103 -30 125 -29 22 -35 22 -318 19 -223 -2 -292 0 -304 10 -11 9
-17 10 -22 2 -4 -6 -6 -79 -6 -161 l2 -151 -43 2 c-73 3 -88 1 -88 -15 0 -9
-6 -12 -15 -9 -30 12 -54 -36 -55 -104 0 -45 -7 -78 -24 -115 -14 -29 -36 -83
-50 -122 -21 -55 -34 -75 -61 -93 -19 -12 -35 -27 -35 -33 0 -5 -3 -8 -7 -6
-5 3 -9 -36 -11 -86 -2 -50 -5 -94 -8 -96 -13 -13 -15 16 -6 86 6 43 8 80 6
83 -3 3 -18 -11 -34 -30 -16 -19 -31 -32 -34 -29 -4 3 3 16 14 29 23 27 25 43
7 59 -8 6 -22 28 -31 50 -10 21 -28 44 -40 49 -19 10 -19 11 -2 11 18 1 18 2
2 20 -10 11 -15 25 -11 30 3 6 -1 11 -9 11 -10 0 -13 6 -10 15 4 9 0 15 -10
15 -10 0 -16 9 -16 25 0 14 -7 28 -15 31 -8 4 -15 12 -15 19 0 7 -7 18 -16 26
-8 7 -13 21 -10 32 3 12 -6 29 -25 48 -16 15 -29 35 -29 44 0 9 -7 18 -15 21
-8 4 -15 14 -15 24 0 18 -37 90 -51 100 -4 3 -15 29 -25 59 -9 30 -25 62 -35
72 -17 17 -39 78 -39 108 0 9 -7 11 -21 8 -12 -3 -18 -3 -15 0 3 4 0 13 -7 21
-9 12 -9 16 1 19 13 5 17 24 5 22 -23 -4 -33 3 -33 22 0 12 -8 23 -20 26 -10
3 -22 14 -25 24 -11 34 -55 66 -55 39 0 -5 7 -10 15 -10 15 0 20 -17 16 -54
-1 -11 4 -23 11 -28 9 -5 6 -8 -9 -8 -12 0 -25 -5 -28 -11 -9 -13 23 -49 44
-49 12 0 13 -2 3 -9 -10 -6 -10 -10 1 -20 18 -14 11 -51 -10 -51 -8 0 -11 -5
-8 -10 4 -6 15 -8 26 -5 11 4 19 3 18 -2 -3 -12 12 -66 23 -79 4 -6 6 -19 2
-28 -4 -11 -1 -16 10 -16 9 0 16 -7 16 -16 0 -8 4 -12 10 -9 5 3 7 0 5 -8 -3
-7 3 -22 15 -32 11 -10 20 -24 20 -32 0 -7 4 -13 9 -13 11 0 8 -61 -3 -79 -5
-8 0 -14 13 -18 23 -6 42 -54 23 -61 -13 -4 -4 -33 16 -48 8 -6 12 -16 8 -22
-4 -7 -1 -12 8 -12 18 0 19 -4 10 -49 -8 -37 -18 -39 -47 -8 -12 13 -32 29
-45 36 -12 7 -20 16 -18 20 3 5 -3 16 -14 26 -11 10 -20 26 -20 37 0 10 -7 18
-15 18 -8 0 -19 16 -25 40 -6 21 -17 46 -25 54 -21 21 -19 28 8 22 12 -3 16
-3 10 0 -7 3 -13 12 -13 20 0 9 -4 13 -9 9 -5 -3 -12 -1 -15 4 -4 5 -1 11 6
14 7 2 3 2 -9 0 -13 -2 -23 1 -23 7 0 5 6 10 13 10 8 0 9 3 1 8 -15 9 -44 67
-44 86 0 8 -13 37 -28 63 -16 26 -31 57 -35 68 -3 11 -13 38 -21 60 -18 49
-21 115 -4 115 6 0 3 6 -8 14 -21 16 -36 4 -27 -21 3 -7 1 -13 -5 -13 -10 0
-18 29 -28 101 -8 49 20 62 35 17 6 -17 9 -34 6 -38 -3 -4 6 -7 20 -6 20 1 25
6 25 27 0 33 9 42 28 26 13 -10 15 -7 9 26 -5 30 -2 40 14 50 10 6 19 15 19
20 0 4 12 19 26 32 15 14 23 25 17 25 -5 0 -24 -16 -43 -35 -37 -39 -51 -43
-70 -20 -12 14 -15 14 -31 -1 -17 -15 -20 -14 -43 10 -14 14 -26 21 -26 16 0
-6 -10 -9 -22 -7 -15 1 -22 -3 -20 -12 1 -7 -3 -18 -11 -25 -25 -20 -50 -134
-66 -297 -5 -53 -14 -105 -19 -115 -26 -49 -45 -221 -43 -393 2 -197 -9 -268
-43 -300 -20 -19 -22 -28 -16 -57 5 -20 16 -45 27 -57 48 -57 102 -104 145
-127 39 -20 49 -22 62 -11 19 16 20 28 3 33 -9 2 -8 7 5 20 16 16 17 15 16
-10 -2 -48 12 -71 45 -80 37 -9 79 -4 71 9 -3 5 -17 9 -32 10 -60 1 -77 38
-34 72 18 15 26 16 37 7 8 -7 12 -16 9 -21 -3 -5 1 -9 9 -9 8 0 17 -7 21 -15
3 -8 12 -15 19 -15 8 0 19 -11 25 -25 10 -21 9 -25 -6 -26 -16 -1 -16 -2 1 -6
9 -2 17 -11 17 -19 0 -8 5 -14 10 -14 6 0 30 -9 52 -20 23 -11 48 -20 55 -20
7 0 13 -5 13 -12 0 -9 3 -9 12 0 7 7 19 12 28 12 8 0 26 10 40 23 14 12 43 28
65 36 22 7 58 27 79 42 22 16 46 29 54 29 8 0 12 6 8 15 -3 8 -1 15 5 15 5 0
24 16 41 35 17 20 33 34 35 31 8 -8 -29 -56 -38 -50 -5 3 -9 -3 -9 -13 0 -17
-34 -47 -55 -48 -5 0 -9 -8 -9 -18 1 -10 -4 -16 -10 -15 -15 4 -86 -33 -86
-45 0 -4 9 -3 19 3 24 12 31 13 31 1 0 -5 -13 -14 -30 -19 -27 -10 -29 -14
-27 -59 2 -44 4 -48 27 -47 14 0 35 4 48 8 l22 7 -22 -24 c-13 -13 -31 -27
-42 -30 -15 -6 -16 -10 -5 -23 10 -13 10 -19 0 -36 -11 -18 -11 -20 2 -15 23
9 40 -14 20 -28 -18 -13 -10 -35 13 -35 26 0 54 -42 43 -63 -14 -25 -2 -40 21
-27 14 7 19 5 23 -9 2 -11 0 -24 -6 -30 -8 -8 -5 -11 11 -11 12 0 22 -6 22
-14 0 -8 14 -19 31 -25 22 -7 29 -15 25 -26 -4 -12 -2 -13 13 -6 15 8 26 3 60
-28 22 -21 41 -44 41 -51 0 -9 5 -7 14 6 12 15 17 17 28 6 10 -10 10 -18 -3
-40 -11 -20 -23 -28 -49 -29 -36 -2 -100 21 -100 36 0 5 -11 12 -25 15 -14 4
-40 21 -58 39 -19 17 -50 45 -70 60 -20 15 -37 35 -37 43 0 13 -12 18 -32 15
-5 0 -8 6 -8 14 0 8 -17 32 -38 55 -22 22 -37 42 -34 45 2 2 -8 18 -24 35 -26
28 -33 30 -71 25 -42 -6 -68 9 -43 25 7 4 10 12 6 18 -3 5 1 35 9 66 8 31 15
60 15 64 0 9 -77 48 -108 55 -12 3 -39 1 -58 -5 -28 -7 -48 -6 -85 6 -26 9
-57 16 -67 16 -11 0 -33 7 -48 15 -16 8 -44 15 -61 15 -20 0 -33 5 -33 13 0 7
-16 13 -38 15 -34 2 -40 -1 -55 -30 -10 -18 -29 -51 -42 -73 -14 -22 -25 -53
-25 -70 0 -55 -12 -105 -25 -105 -13 0 -263 242 -331 320 -21 25 -59 73 -84
105 -25 33 -48 62 -51 65 -28 23 -205 309 -264 428 -314 624 -399 1300 -250
1985 13 62 29 126 34 142 6 17 23 68 37 115 42 134 85 239 172 420 15 30 29
57 32 60 3 3 20 34 39 69 55 103 227 355 286 417 5 6 39 44 75 85 81 91 145
155 240 239 40 36 78 70 84 75 69 64 309 229 416 286 36 19 67 36 70 39 14 14
215 105 322 146 290 111 597 178 888 194 74 4 142 7 150 8 8 1 93 -3 189 -8z
m-1614 -3509 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-71 -26
c-4 -8 -10 -15 -15 -15 -5 0 -9 7 -9 15 0 8 7 15 15 15 9 0 12 -6 9 -15z
m-101 -370 c12 -6 21 -18 21 -28 -1 -9 2 -17 6 -17 4 0 10 -20 13 -45 6 -43
35 -121 58 -155 5 -8 16 -31 24 -50 8 -19 23 -48 32 -65 9 -16 25 -47 36 -67
10 -21 25 -38 33 -38 8 0 15 -10 15 -22 1 -13 2 -30 3 -38 1 -8 10 -31 21 -50
10 -19 18 -41 17 -47 -2 -7 1 -13 6 -13 5 0 12 -8 16 -17 3 -10 19 -38 35 -63
17 -25 38 -61 47 -80 10 -19 28 -50 42 -68 13 -18 21 -40 18 -48 -8 -20 -31
-12 -29 10 1 13 -4 16 -17 13 -21 -5 -65 35 -53 48 5 4 0 5 -9 2 -12 -4 -18
-1 -18 8 0 8 -8 15 -19 15 -10 0 -26 11 -35 25 -9 14 -21 23 -26 20 -4 -3 -6
4 -3 16 4 15 0 22 -15 26 -12 3 -18 10 -15 19 3 8 -2 17 -11 20 -9 3 -16 15
-16 26 0 10 -4 17 -9 14 -16 -10 -58 78 -66 137 -8 70 -24 112 -59 152 -14 17
-26 40 -26 53 0 12 -4 22 -9 22 -19 0 -53 139 -54 222 -2 84 13 153 20 98 2
-15 14 -30 26 -35z m1557 -119 c14 -2 -23 -4 -82 -5 -59 0 -109 2 -111 7 -5 6
135 5 193 -2z m-1036 -262 c4 -11 1 -14 -11 -12 -9 2 -18 9 -21 16 -6 18 25
15 32 -4z m923 10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-898 -132 c-10 -16 -10 -23 0 -36 7 -8 9 -21 5 -27 -4 -8 -3 -9 4 -5 7 4 12
0 12 -10 0 -10 -3 -14 -7 -11 -3 4 -14 0 -24 -9 -17 -15 -20 -14 -48 16 -16
18 -27 38 -24 45 2 7 -5 16 -16 20 -12 3 -21 11 -21 16 0 15 20 10 42 -11 30
-28 45 -25 63 11 9 17 19 29 21 26 3 -3 0 -14 -7 -25z m738 -138 c14 -14 14
-17 -6 -30 -11 -8 -21 -24 -21 -35 0 -14 -7 -19 -30 -19 -16 0 -30 -4 -30 -10
0 -5 7 -10 16 -10 10 0 14 -6 11 -15 -9 -21 6 -26 87 -36 84 -9 129 -26 121
-47 -3 -8 3 -16 15 -19 25 -7 27 -33 2 -33 -10 0 -28 -7 -41 -16 -17 -12 -43
-16 -102 -15 -95 2 -100 4 -122 40 -21 36 -22 70 -2 77 8 4 15 16 15 29 0 17
-7 24 -29 28 -37 8 -73 45 -69 72 7 45 149 76 185 39z m-687 -74 c0 -5 -4 -10
-9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-576 -35 c3 -9 0
-15 -9 -15 -8 0 -15 7 -15 15 0 8 4 15 9 15 5 0 11 -7 15 -15z m802 -67 c5 -8
2 -9 -10 -5 -13 5 -17 3 -13 -6 3 -8 -1 -14 -8 -14 -10 0 -11 5 -4 19 11 21
25 23 35 6z m904 -28 c18 -12 2 -12 -25 0 -13 6 -15 9 -5 9 8 0 22 -4 30 -9z
m-50 -16 c0 -14 -3 -14 -15 -4 -8 7 -15 14 -15 16 0 2 7 4 15 4 8 0 15 -7 15
-16z m-40 -14 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9
-4 9 -10z m-114 -56 c-3 -9 -6 -18 -6 -21 0 -3 -7 0 -15 7 -13 11 -13 15 -3
21 21 13 31 10 24 -7z m-1251 -218 c34 -27 29 -44 -7 -25 -23 12 -38 28 -38
42 0 13 14 7 45 -17z m-179 -92 c-7 -25 -5 -26 21 -21 93 19 127 23 159 18 36
-6 36 -6 16 -24 -12 -11 -26 -16 -32 -12 -12 7 -110 -19 -151 -41 -14 -7 -29
-10 -33 -7 -4 4 -4 -1 0 -11 7 -18 6 -18 -22 0 -16 10 -23 11 -16 4 16 -18 15
-30 -2 -30 -7 0 -19 -7 -26 -15 -7 -8 -17 -12 -23 -8 -5 3 -16 -2 -22 -11 -18
-23 -64 -31 -71 -11 -4 8 -13 15 -20 15 -19 0 -64 29 -64 41 0 5 8 9 17 9 46
0 123 45 123 71 0 5 15 16 33 26 27 15 48 21 111 32 4 0 5 -11 2 -25z m224
-109 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z
m-80 -3 c0 -5 -29 -14 -64 -21 -38 -8 -72 -21 -82 -32 -9 -10 -22 -19 -28 -19
-6 0 -22 -9 -36 -20 -28 -22 -74 -27 -68 -8 10 28 186 107 241 108 20 0 37 -4
37 -8z m2110 -73 c0 -4 -6 -14 -14 -22 -11 -11 -12 -17 -3 -26 6 -6 7 -11 1
-11 -5 0 -16 6 -22 12 -8 8 -15 9 -20 3 -13 -18 -52 -36 -52 -24 0 6 -8 1 -18
-11 -16 -19 -22 -21 -40 -11 -12 6 -22 7 -22 2 0 -5 -9 -7 -20 -4 -11 3 -20
12 -20 20 0 8 -4 12 -10 8 -17 -11 -57 6 -70 30 -23 43 -3 52 116 53 58 0 108
2 110 5 7 7 84 -14 84 -24z m-2142 5 c15 -15 15 -16 -5 -10 -15 5 -28 -2 -51
-28 -17 -19 -33 -44 -37 -55 -8 -26 -26 -27 -56 -1 -24 20 -24 20 -4 35 11 8
25 19 31 24 17 14 87 51 97 51 5 0 16 -7 25 -16z m622 1 c0 -12 -19 -12 -23 0
-4 9 -1 12 9 8 7 -3 14 -7 14 -8z m-469 -17 c17 3 35 -14 41 -38 2 -8 7 -18
12 -22 9 -6 14 -33 19 -89 1 -15 13 -36 27 -48 l25 -20 -25 -1 c-25 -1 -25 -1
-2 -10 12 -5 22 -14 22 -20 0 -7 -9 -9 -22 -4 -22 6 -22 6 2 -13 22 -18 89
-42 322 -119 26 -9 64 -17 85 -18 21 -1 45 -5 53 -8 31 -12 113 -29 118 -24 2
3 -7 10 -22 15 -33 13 -34 23 -1 15 15 -4 25 -2 25 5 0 6 -9 11 -20 11 -22 0
-25 7 -11 29 5 7 13 11 18 8 5 -3 16 3 23 14 8 10 20 19 28 19 7 0 10 4 7 10
-3 5 -19 7 -35 3 -23 -4 -30 -2 -30 11 0 9 4 16 9 16 15 0 23 63 9 72 -9 5 -4
8 12 8 36 0 57 15 34 24 -12 4 -14 10 -7 17 13 13 41 2 47 -19 3 -10 4 -1 4
18 -2 66 2 80 18 80 12 0 15 6 10 26 -4 16 -3 23 3 20 9 -6 14 -38 25 -141 2
-16 5 -30 6 -30 1 0 3 -27 6 -60 2 -33 9 -77 15 -97 7 -20 9 -52 6 -71 l-5
-34 51 -6 c101 -12 263 -17 237 -8 -14 5 -52 10 -85 12 l-60 2 45 6 45 6 -1
53 c-1 44 1 52 15 50 9 -2 16 -10 16 -18 0 -19 30 -21 75 -4 19 7 29 13 23 13
-18 1 -16 31 2 31 21 0 19 13 -6 28 -15 10 -18 17 -11 24 14 14 42 3 42 -17 0
-29 26 -44 81 -47 37 -2 53 -7 52 -16 -2 -6 6 -21 17 -32 20 -20 29 -67 10
-55 -5 3 -7 0 -4 -8 4 -11 16 -14 43 -10 23 3 41 1 45 -6 5 -7 -2 -11 -16 -12
-17 -1 -14 -3 12 -10 38 -10 117 -3 140 12 9 5 10 9 3 9 -10 0 -12 39 -3 60 1
3 1 24 0 48 -2 45 5 52 26 25 7 -10 16 -14 20 -10 5 4 2 12 -5 17 -8 4 -11 15
-8 25 8 19 27 11 27 -11 0 -8 9 -14 20 -14 11 0 29 -9 40 -20 12 -12 25 -18
29 -15 16 9 38 -5 71 -50 18 -24 38 -41 45 -39 7 3 16 1 19 -4 13 -21 -211
-57 -452 -73 -191 -13 -463 -1 -658 30 -168 27 -457 105 -579 156 -16 7 -66
27 -110 45 -105 42 -219 103 -183 97 20 -3 29 1 33 14 4 11 3 24 -1 31 -8 12
-6 123 2 151 3 10 10 14 17 10 7 -4 19 -6 28 -5z m523 -74 c3 -8 2 -12 -4 -9
-6 3 -10 10 -10 16 0 14 7 11 14 -7z m56 -8 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1
-10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-30 -36 c0 -5 -5 -10 -11 -10 -5 0 -7
5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-730 -25 c11 -13 10 -14 -4 -9 -9 3
-16 10 -16 15 0 13 6 11 20 -6z m1450 -125 c0 -5 -7 -10 -15 -10 -8 0 -15 5
-15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m50 -10 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-151 -16 c-10 -13 -10 -17 1 -24
8 -5 10 -15 6 -26 -4 -11 -10 -14 -14 -8 -4 6 -15 13 -24 17 -24 9 -23 28 2
34 11 3 20 9 20 14 0 5 5 9 11 9 8 0 7 -5 -2 -16z m-89 -14 c0 -12 -7 -20 -16
-20 -13 0 -14 3 -5 13 6 8 8 17 4 20 -3 4 -1 7 5 7 7 0 12 -9 12 -20z m-16
-55 c-4 -8 -14 -15 -24 -15 -10 0 -20 7 -24 15 -4 11 2 15 24 15 22 0 28 -4
24 -15z m16 -66 c0 -16 -8 -19 -44 -19 -25 0 -48 -5 -51 -10 -4 -6 -11 -8 -16
-5 -5 3 1 16 14 29 29 30 97 33 97 5z m900 -19 c-14 -4 -34 -8 -45 -8 -16 0
-15 2 5 8 14 4 34 8 45 8 16 0 15 -2 -5 -8z"/>
<path d="M6542 9963 c-35 -3 -45 -9 -57 -34 -9 -16 -19 -29 -23 -29 -4 0 -13
-14 -21 -31 -8 -18 -18 -28 -27 -25 -16 6 -44 -19 -44 -40 0 -8 -4 -14 -10
-14 -5 0 -10 11 -10 25 0 14 -7 28 -16 32 -8 3 -13 11 -10 18 4 10 -2 11 -26
4 -40 -11 -40 -11 -42 -58 -2 -40 -30 -91 -54 -99 -7 -2 -12 3 -12 11 0 19
-40 11 -58 -11 -9 -12 -15 -13 -24 -4 -8 8 -20 2 -45 -23 -19 -19 -39 -32 -45
-29 -5 4 -25 -8 -43 -26 -39 -37 -55 -87 -34 -102 11 -8 11 -9 -1 -5 -8 2 -16
15 -18 29 -2 20 -10 27 -34 32 -38 7 -48 0 -24 -17 26 -19 17 -52 -15 -52 -20
0 -25 -4 -21 -17 4 -18 -8 -24 -22 -11 -4 4 -36 8 -71 8 -47 1 -66 -2 -70 -12
-8 -21 13 -53 30 -47 9 4 22 1 30 -6 9 -7 27 -10 45 -6 22 4 30 2 30 -9 0 -17
-74 -95 -90 -95 -15 0 -12 -8 10 -30 26 -26 28 -25 42 8 9 22 12 24 15 9 6
-23 -32 -61 -49 -51 -7 4 -34 9 -61 11 -45 3 -49 1 -58 -26 -10 -27 -44 -51
-74 -51 -15 0 -19 -12 -15 -39 1 -8 -4 -12 -13 -8 -20 7 -28 -15 -20 -53 5
-27 3 -30 -21 -30 -27 0 -35 -15 -13 -23 10 -4 10 -6 0 -6 -7 -1 -13 -6 -13
-12 0 -6 7 -9 15 -5 19 7 19 1 -1 -29 -9 -14 -23 -25 -30 -25 -8 0 -14 -6 -14
-14 0 -7 -10 -20 -22 -29 l-23 -14 21 -39 c23 -41 22 -64 -3 -64 -9 0 -13 -8
-11 -22 2 -17 9 -22 27 -20 18 2 22 -2 17 -14 -3 -9 1 -29 11 -44 16 -28 16
-29 -13 -57 -16 -15 -42 -45 -58 -65 -15 -20 -33 -35 -41 -32 -8 3 -15 -7 -19
-30 -5 -28 -12 -36 -37 -41 -21 -5 -29 -12 -27 -23 5 -25 34 -26 68 -2 25 18
34 20 45 10 12 -10 13 -16 3 -27 -9 -11 -9 -16 3 -24 8 -5 17 -9 20 -9 3 0 9
-21 12 -47 3 -27 9 -56 13 -66 5 -13 1 -20 -15 -24 -12 -3 -21 -13 -21 -25 0
-15 3 -17 19 -8 26 13 31 13 31 -4 0 -8 -6 -16 -12 -19 -10 -4 -10 -8 0 -23
15 -24 27 -50 38 -88 11 -36 25 -37 41 -5 l13 24 0 -25 c1 -53 66 -90 121 -71
39 14 39 14 39 0 0 -7 -7 -17 -16 -23 -31 -23 -16 -146 19 -161 16 -7 24 -22
28 -53 3 -23 10 -47 16 -53 17 -17 34 -1 27 25 -7 29 30 66 48 48 17 -17 41
-15 35 2 -3 8 2 17 11 21 9 3 17 5 17 4 0 -1 10 3 22 9 21 12 44 0 24 -13 -6
-3 -8 -12 -5 -20 3 -8 -1 -29 -10 -46 -22 -43 -20 -51 12 -89 22 -25 39 -33
74 -37 41 -5 46 -8 57 -42 22 -64 72 -154 123 -224 54 -72 55 -84 27 -174 -10
-30 -14 -33 -52 -33 -53 0 -106 -29 -187 -100 -38 -34 -80 -61 -109 -70 -49
-15 -55 -22 -45 -54 3 -10 1 -23 -5 -29 -8 -8 -13 -2 -17 21 -7 33 -34 45 -34
15 -1 -16 -2 -16 -15 1 -15 19 -15 19 -15 -1 0 -12 8 -23 19 -26 11 -3 22 -17
26 -36 7 -32 31 -43 37 -15 3 13 6 12 24 -4 16 -16 31 -19 65 -15 84 9 109
-50 26 -60 -78 -10 -107 -19 -107 -33 0 -9 -12 -14 -34 -14 -19 0 -38 -4 -41
-10 -3 -5 -17 -10 -31 -10 -14 0 -23 -4 -20 -9 5 -8 26 -7 131 3 17 2 38 6 47
10 9 4 25 6 35 3 14 -4 13 -5 -5 -6 -21 -1 -23 -4 -16 -33 6 -29 5 -31 -10
-19 -14 12 -16 11 -16 -8 0 -23 11 -26 40 -11 13 7 21 7 25 0 12 -19 27 -10
22 12 -8 29 11 66 24 47 8 -12 10 -12 18 0 7 11 13 10 35 -5 25 -19 30 -31 27
-69 -1 -13 1 -16 6 -9 8 11 34 18 60 15 6 0 14 -13 16 -28 2 -16 3 -11 2 10
-4 46 15 49 69 12 20 -13 42 -22 50 -19 7 3 21 -3 31 -13 18 -17 18 -17 13 10
-6 33 16 38 27 5 3 -14 8 -18 11 -11 3 7 21 15 40 19 18 3 31 10 28 15 -3 5 6
9 20 9 14 0 26 -3 26 -7 0 -5 27 -8 61 -8 33 1 62 -2 64 -5 4 -7 199 -3 250 4
17 2 36 5 43 5 7 1 11 5 7 10 -5 9 -76 8 -115 -1 -13 -2 -15 13 -15 97 0 83 3
100 16 103 12 2 16 -9 17 -58 2 -62 7 -80 22 -80 4 0 4 17 -1 38 -7 34 -6 55
8 105 10 37 22 14 28 -57 4 -40 11 -79 17 -87 6 -9 7 1 2 31 -8 48 -7 130 1
138 2 3 11 0 18 -6 11 -9 21 -5 45 19 18 17 32 40 32 50 0 14 7 19 30 19 22 0
30 5 30 18 0 12 2 14 8 4 6 -9 12 -6 20 12 6 13 19 27 30 30 14 5 17 13 14 36
-6 41 6 89 18 70 5 -8 12 -11 16 -8 3 4 -4 22 -17 40 -42 58 -65 179 -39 198
12 8 90 139 90 150 0 6 4 10 8 10 12 0 36 104 29 121 -3 8 -6 3 -6 -11 -1 -19
-4 -22 -10 -11 -8 12 -17 13 -64 1 -50 -11 -62 -11 -113 6 -56 19 -79 40 -52
48 7 2 26 7 41 11 46 10 121 95 147 167 23 64 45 91 67 83 13 -4 18 10 27 70
2 17 3 -27 2 -98 -2 -83 1 -130 8 -136 45 -35 111 -5 85 37 -7 12 -21 23 -31
25 -11 3 -16 11 -12 20 4 12 17 14 54 10 39 -4 58 0 90 18 40 22 51 41 54 94
0 14 6 25 12 25 15 0 40 31 47 58 5 23 -8 31 -16 10 -2 -7 -8 -10 -12 -6 -4 4
-5 14 -2 23 7 17 48 20 64 4 18 -18 105 17 133 56 14 18 27 33 30 33 21 -1 29
4 24 16 -3 7 2 16 10 19 24 9 19 25 -10 40 l-27 14 41 19 c23 10 42 24 42 30
0 7 9 14 19 17 11 3 22 17 26 36 4 20 13 31 23 31 14 0 15 -2 3 -9 -12 -8 -13
-12 -2 -25 11 -12 17 -14 27 -5 8 6 14 17 14 23 -1 21 -38 56 -53 50 -8 -3
-18 -1 -21 4 -7 12 22 25 35 16 5 -3 10 3 10 13 1 10 3 25 3 33 1 8 5 25 9 37
5 15 3 30 -8 46 -16 25 -12 77 5 77 9 0 40 57 40 73 0 5 -12 6 -27 3 -21 -5
-24 -4 -13 3 13 9 13 12 -1 23 -22 17 -31 59 -17 76 9 10 9 15 -1 21 -7 5 -12
11 -12 15 1 7 -7 6 -28 -2 -8 -3 -19 2 -26 11 -7 10 -20 14 -33 10 -12 -3 -22
-1 -22 5 0 16 48 21 54 6 3 -8 10 -14 16 -14 12 0 45 68 42 87 -2 6 7 18 20
27 l22 15 -22 23 c-78 84 -102 111 -102 119 0 4 -10 11 -22 14 -13 4 -35 20
-50 36 -15 16 -35 29 -45 29 -10 0 -24 9 -31 20 -7 12 -16 19 -20 16 -5 -3 -9
13 -11 36 -2 46 -29 80 -67 84 -35 4 -54 22 -54 50 0 19 -5 24 -24 24 -27 0
-42 15 -26 25 18 11 10 32 -12 31 -20 -1 -23 4 -22 32 2 28 -1 32 -25 32 -14
0 -33 5 -41 10 -13 8 -13 10 -1 10 12 0 12 3 3 12 -7 7 -12 22 -12 35 0 13 -4
23 -10 23 -5 0 -10 9 -10 20 0 12 -10 24 -24 30 -13 5 -25 13 -27 19 -2 6 -20
13 -39 17 -26 4 -31 3 -20 -5 13 -9 12 -12 -5 -25 -11 -8 -24 -11 -30 -8 -5 4
-31 7 -58 8 -45 1 -47 0 -45 -25 2 -17 -2 -26 -13 -29 -12 -2 -15 3 -12 20 3
18 -2 27 -21 38 -14 7 -26 19 -26 27 0 7 -9 22 -20 33 -24 24 -26 40 -5 40 13
0 14 4 5 20 -6 11 -20 19 -33 19 -12 0 -31 2 -43 6 -13 4 -27 1 -37 -9 -8 -9
-20 -16 -26 -16 -6 0 -11 -4 -11 -10 0 -5 -12 -10 -27 -10 -22 0 -25 2 -13 10
20 12 0 33 -26 28 -13 -2 -19 -12 -19 -30 0 -14 -3 -30 -8 -34 -12 -12 -44 29
-51 68 -4 20 -12 38 -19 41 -8 3 -6 11 7 26 22 24 27 23 -72 14z m12 -53 c37
14 36 14 29 -22 -6 -34 -23 -62 -23 -39 0 15 -25 41 -39 41 -6 0 -11 8 -11 18
0 12 2 14 8 4 7 -10 15 -10 36 -2z m-41 -85 c-20 -15 -33 -11 -33 9 0 9 9 12
25 8 24 -4 24 -5 8 -17z m327 16 c0 -5 9 -12 19 -16 19 -6 19 -7 3 -26 -10
-11 -29 -19 -49 -19 -34 0 -43 16 -21 38 9 9 9 12 -2 13 -10 0 -7 4 5 9 27 12
45 12 45 1z m88 -154 c2 -10 -3 -17 -12 -17 -10 0 -16 9 -16 21 0 24 23 21 28
-4z m315 -63 c4 -25 3 -26 -27 -19 -17 4 -39 8 -48 8 -12 1 -14 5 -7 13 5 6
20 9 34 6 15 -2 25 0 25 7 0 22 18 10 23 -15z m-1443 -174 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1748 -168 c2 -7 -3
-12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m-1898 -62 c8 -5 11 -12 8
-16 -10 -9 -38 3 -38 16 0 12 10 13 30 0z m2114 -174 c-5 -20 -2 -26 10 -26 9
0 16 -4 16 -10 0 -5 -7 -10 -15 -10 -16 0 -30 42 -21 65 3 9 0 15 -9 15 -17 0
-28 17 -20 30 12 20 46 -37 39 -64z m-985 -657 c42 -45 81 -59 184 -66 67 -5
67 -5 92 -47 14 -24 25 -46 25 -51 0 -4 -29 -4 -64 0 -55 5 -77 2 -145 -20
-45 -15 -98 -36 -118 -46 -21 -10 -42 -19 -49 -19 -6 0 -24 -16 -39 -35 -20
-27 -24 -39 -16 -49 21 -25 -16 -78 -49 -72 -63 12 -84 26 -60 41 6 3 10 10
10 15 0 12 -53 70 -65 70 -5 0 -22 22 -37 49 -30 55 -25 74 24 85 36 8 90 55
84 73 -4 8 -1 11 5 8 14 -9 38 24 39 53 0 19 4 22 20 17 14 -5 20 -2 20 10 0
12 9 15 35 13 19 -1 37 1 41 5 14 13 27 7 63 -34z m-394 -199 c3 -5 2 -10 -4
-10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m702 -16 c-3 -4 5
-27 18 -52 13 -25 22 -49 19 -54 -3 -4 -19 -8 -36 -8 -23 0 -29 4 -24 15 4 11
-7 21 -37 35 -65 30 -141 12 -88 -22 12 -7 21 -21 21 -32 0 -17 -6 -18 -77
-11 -43 4 -80 9 -82 10 -8 8 12 25 30 25 11 0 17 5 14 10 -6 9 14 14 43 11 7
-1 12 5 10 13 -2 8 13 27 32 40 28 20 47 25 99 26 35 0 62 -3 58 -6z m50 -66
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-842 -38 c3 -5 16 -10
27 -10 12 0 19 -3 15 -6 -3 -4 -32 -3 -64 1 -48 7 -54 9 -33 16 36 10 48 10
55 -1z m803 -27 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z
m-768 -13 c11 -7 7 -10 -17 -10 -18 0 -33 5 -33 10 0 13 30 13 50 0z m619 -9
c8 -5 -5 -7 -35 -4 -27 3 -51 7 -53 9 -9 8 75 3 88 -5z m-636 -18 c-7 -2 -21
-2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m632 -13 c-28 -12 -85 -12 -85 0
0 6 24 10 53 9 38 0 47 -3 32 -9z m-317 -38 c3 -10 -5 -13 -29 -10 -18 2 -34
9 -37 16 -3 10 5 13 29 10 18 -2 34 -9 37 -16z m404 -9 c29 -26 24 -92 -12
-141 -35 -49 -65 -66 -145 -82 -71 -14 -120 -10 -120 8 0 8 -7 19 -17 26 -32
24 -61 94 -53 132 5 27 9 33 19 25 7 -6 16 -8 19 -5 4 4 17 0 29 -8 42 -27 97
-27 166 0 34 13 67 33 73 43 12 23 17 24 41 2z m-322 3 c0 -3 -4 -8 -10 -11
-5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-982 -38 c-2 -6 -10 -14
-16 -16 -7 -2 -10 2 -6 12 7 18 28 22 22 4z m382 -24 c0 -21 -4 -33 -10 -29
-5 3 -10 19 -10 36 0 16 5 29 10 29 6 0 10 -16 10 -36z m510 -26 c38 -80 50
-95 80 -100 13 -1 25 -9 28 -16 2 -7 -2 -10 -12 -6 -24 9 -19 -9 9 -36 18 -17
23 -29 18 -48 -5 -21 -3 -24 12 -18 18 6 55 -18 55 -36 0 -5 12 -19 27 -32 14
-13 23 -28 19 -34 -7 -11 -69 -32 -111 -37 -62 -7 -104 -18 -100 -25 3 -4 -28
-1 -68 6 -39 8 -79 15 -87 15 -8 1 15 5 51 9 42 4 73 13 84 23 9 9 24 17 34
17 21 0 61 32 61 49 0 6 5 11 10 11 6 0 10 4 10 9 0 9 -85 7 -141 -4 -37 -7
-68 4 -113 39 -18 15 -18 16 6 58 29 50 45 138 25 139 -9 0 -9 2 1 6 6 2 12
10 12 17 0 17 33 56 48 56 7 0 26 -28 42 -62z m620 42 c0 -13 -7 -20 -20 -20
-11 0 -20 7 -20 16 0 8 4 13 9 9 5 -3 12 -1 16 5 10 16 15 12 15 -10z m-998
-33 c4 -6 31 -10 60 -10 89 2 138 -31 115 -77 -13 -25 -84 -52 -169 -64 -45
-7 -49 -5 -83 30 -40 41 -71 94 -55 94 5 0 10 10 10 23 0 23 3 23 58 19 31 -3
60 -9 64 -15z m203 -200 c0 -26 -20 -25 -23 2 -3 16 1 22 10 19 7 -3 13 -12
13 -21z m-145 -1 c0 -7 -11 -25 -24 -39 -25 -27 -37 -27 -98 -2 -19 7 -31 32
-22 46 3 5 36 9 75 9 51 0 69 -3 69 -14z m13 -249 c30 -55 29 -73 -2 -38 -11
13 -24 20 -29 17 -15 -9 -82 76 -82 103 0 20 2 21 15 11 12 -10 15 -9 15 6 0
24 43 -27 83 -99z m626 41 c-12 -18 -31 -43 -42 -55 -17 -19 -19 -31 -13 -78
8 -68 -7 -103 -67 -168 -58 -61 -92 -76 -184 -81 -86 -5 -138 11 -180 56 -42
45 -54 120 -13 83 14 -12 40 -16 119 -16 131 0 170 19 209 101 42 88 39 101
-11 52 -41 -41 -45 -42 -108 -42 -37 0 -70 -5 -77 -12 -9 -9 -12 -9 -12 0 0 6
-6 9 -14 6 -16 -6 -56 12 -56 24 0 5 27 6 61 4 41 -3 64 0 72 8 10 10 1 11
-40 7 -31 -4 -53 -2 -53 3 0 6 -10 10 -23 10 -26 0 -49 31 -37 50 5 9 13 10
26 2 12 -7 25 -7 37 -1 10 6 27 7 38 4 11 -4 25 -2 32 3 7 5 38 13 69 16 32 4
60 12 64 17 4 6 11 6 20 -2 10 -8 14 -8 14 0 0 6 5 11 12 11 6 0 1 -11 -11
-24 -33 -35 -22 -58 11 -26 67 66 80 74 126 80 26 3 49 5 51 4 2 -2 -7 -18
-20 -36z m-1133 -503 c-16 -17 -36 -20 -36 -6 0 10 17 19 38 20 10 1 10 -3 -2
-14z m27 -77 c-2 -5 -10 -8 -18 -8 -8 0 -16 3 -18 8 -3 4 5 7 18 7 13 0 21 -3
18 -7z m839 -133 c3 -89 3 -90 -21 -93 l-24 -4 6 81 c3 45 11 88 18 97 6 8 13
13 14 12 2 -2 5 -43 7 -93z m-63 -20 c0 -73 -1 -75 -25 -75 -18 0 -24 5 -24
23 0 13 4 27 9 33 9 8 18 46 20 82 1 6 5 12 11 12 5 0 9 -33 9 -75z m-60 -47
c1 -12 -6 -18 -19 -18 -29 0 -34 23 -14 62 l18 33 7 -30 c4 -16 7 -38 8 -47z
m-136 35 c-10 -29 -14 -34 -24 -27 -6 4 -6 10 0 18 6 7 8 16 4 19 -3 4 1 7 10
7 11 0 14 -5 10 -17z m67 -14 c0 -10 -2 -19 -4 -19 -2 0 -11 -3 -20 -6 -12 -5
-16 0 -16 19 0 24 16 39 33 30 4 -2 7 -13 7 -24z m-150 -49 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-113 -6 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M7098 8073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6370 9860 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M6970 9820 c-22 -14 0 -30 33 -23 15 3 26 11 24 17 -5 15 -38 19 -57
6z"/>
<path d="M6000 9719 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5546 9254 c-17 -17 -20 -27 -10 -37 10 -10 44 12 44 28 0 22 -17 27
-34 9z"/>
<path d="M5395 9210 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M5470 9180 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7783 9159 c10 -13 20 -32 23 -41 8 -22 34 -32 34 -13 0 9 -6 25 -12
38 -7 12 -13 23 -13 23 0 1 -11 5 -25 9 -24 8 -24 7 -7 -16z"/>
<path d="M5421 9054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7825 8900 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5315 8859 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M5315 8820 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5470 8020 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7258 7843 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7152 7740 c-1 -14 -5 -36 -8 -50 -5 -19 -4 -22 5 -14 7 7 11 28 8
50 -2 22 -4 28 -5 14z"/>
<path d="M7155 7110 c-3 -5 0 -18 7 -30 13 -20 28 -27 28 -12 0 4 3 17 6 30 5
17 2 22 -14 22 -12 0 -23 -4 -27 -10z"/>
<path d="M7103 7060 c-2 -24 10 -26 28 -3 10 11 10 14 2 9 -7 -4 -13 -2 -13 3
0 20 -16 11 -17 -9z"/>
<path d="M7270 6966 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M7174 6931 c20 -22 26 -25 26 -13 0 4 -10 13 -22 21 -23 14 -23 14
-4 -8z"/>
<path d="M7260 6926 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24 12z"/>
<path d="M7145 6900 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7220 6896 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M7318 6873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6945 6830 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7278 6833 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7342 6795 c13 -13 209 -63 271 -69 85 -8 89 8 5 23 -91 16 -106 19
-178 35 -67 16 -107 20 -98 11z"/>
<path d="M6871 6704 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7723 6713 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5528 6703 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5595 6700 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M7778 6703 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6985 6690 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M5736 6682 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
<path d="M7830 6680 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M5770 6655 c0 -8 7 -15 16 -15 14 0 14 3 4 15 -7 8 -14 15 -16 15 -2
0 -4 -7 -4 -15z"/>
<path d="M6875 6661 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M5945 6650 c-3 -5 -1 -10 5 -10 6 0 8 -10 4 -24 -3 -13 -1 -27 5 -30
7 -5 11 2 11 18 0 13 3 31 6 40 7 18 -21 23 -31 6z"/>
<path d="M5847 6603 c4 -26 3 -34 -5 -29 -7 4 -22 0 -34 -8 l-22 -16 23 0 c14
0 35 -13 52 -32 30 -35 36 -48 19 -48 -5 0 -10 -5 -10 -11 0 -14 63 -3 68 11
7 19 31 10 34 -12 l3 -23 7 22 c5 18 0 25 -23 35 -19 9 -29 20 -29 35 0 47
-43 113 -74 113 -11 0 -13 -8 -9 -37z m73 -69 c0 -8 -5 -12 -10 -9 -6 4 -8 11
-5 16 9 14 15 11 15 -7z"/>
<path d="M5765 6620 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5015 6600 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6151 6594 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7775 6590 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5271 6574 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6180 6565 c0 -9 9 -15 21 -15 15 0 19 -4 15 -16 -4 -11 -1 -14 11
-12 9 2 16 6 15 11 -1 4 -2 17 -2 30 0 18 -2 19 -10 7 -8 -12 -13 -13 -30 -2
-17 10 -20 10 -20 -3z"/>
<path d="M5950 6540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7691 6454 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6200 6449 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8070 6448 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M5906 6432 c-5 -8 -4 -18 3 -25 6 -6 11 -16 11 -21 0 -6 -4 -4 -10 4
-5 8 -12 11 -16 7 -4 -4 -2 -13 5 -20 10 -9 15 -8 25 6 15 20 38 23 30 4 -6
-16 23 -43 33 -33 4 4 1 11 -6 15 -8 5 -11 17 -7 28 5 17 2 19 -23 15 -22 -3
-30 0 -33 15 -3 14 -5 15 -12 5z"/>
<path d="M6200 6420 c0 -5 9 -10 20 -10 14 0 20 -7 20 -21 0 -11 5 -17 10 -14
6 4 8 11 5 16 -8 12 16 11 35 -1 13 -8 12 -11 -5 -24 -11 -9 -15 -16 -8 -16 6
0 20 -3 30 -6 15 -5 16 -4 3 6 -11 9 -12 13 -2 17 16 6 15 21 -2 39 -18 17
-106 30 -106 14z"/>
<path d="M7630 6410 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8085 6399 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M8165 6390 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5214 6376 c-4 -9 -4 -20 0 -23 3 -4 12 -1 18 5 9 9 9 15 0 24 -9 9
-13 7 -18 -6z"/>
<path d="M6343 6375 c-8 -22 6 -33 17 -15 13 20 12 30 0 30 -6 0 -13 -7 -17
-15z"/>
<path d="M7671 6364 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5130 6349 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5917 6344 c-12 -13 -8 -20 14 -21 11 -1 19 3 17 9 -5 15 -22 21 -31
12z"/>
<path d="M5970 6330 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6350 6320 c-8 -5 -10 -10 -5 -11 62 -6 67 -5 49 8 -21 15 -24 16
-44 3z"/>
<path d="M5934 6299 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16 11z"/>
<path d="M6295 6301 c-8 -14 10 -22 30 -15 13 5 14 8 3 15 -17 11 -26 11 -33
0z"/>
<path d="M5145 6290 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M7993 6273 c-7 -2 -13 -19 -12 -36 l1 -32 10 28 c5 15 14 27 19 27 5
0 9 5 9 10 0 11 -7 12 -27 3z"/>
<path d="M5955 6230 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M8133 6218 c3 -12 0 -33 -8 -47 -7 -14 -16 -39 -20 -54 -3 -15 -10
-25 -15 -22 -6 3 -10 -3 -10 -14 0 -19 1 -19 26 4 14 13 23 28 20 33 -3 6 1
26 9 46 18 42 19 76 4 76 -7 0 -9 -9 -6 -22z"/>
<path d="M5991 6194 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5960 6170 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7941 6122 c-11 -32 -24 -73 -27 -90 -4 -18 -10 -32 -15 -32 -5 0 -9
-9 -9 -20 0 -12 -7 -34 -15 -50 -8 -16 -33 -85 -56 -152 -35 -107 -53 -159
-89 -253 -24 -61 -39 -140 -43 -216 -2 -49 -12 -107 -23 -134 l-18 -48 34 -30
c18 -16 48 -60 66 -97 17 -37 38 -70 47 -73 8 -3 30 7 48 22 71 57 202 69 268
25 22 -14 42 -35 45 -47 3 -12 26 -37 51 -56 25 -18 45 -40 45 -47 0 -9 12
-14 33 -14 31 0 32 1 33 41 1 35 -5 47 -37 80 -21 22 -44 57 -50 79 -7 22 -16
40 -20 40 -5 0 -18 10 -31 23 -20 19 -33 22 -98 21 -96 -1 -142 -10 -205 -38
-92 -42 -111 -46 -129 -25 -18 21 -22 59 -6 59 6 0 10 6 10 14 0 7 16 24 35
37 19 13 35 30 35 37 0 6 5 12 10 12 6 0 10 7 10 15 0 8 7 15 16 15 13 0 15 7
11 30 -6 28 7 43 15 18 7 -20 26 1 21 22 -4 15 -13 19 -38 17 -27 -2 -38 -10
-60 -44 -14 -23 -27 -51 -28 -62 -1 -11 -7 -23 -14 -25 -7 -3 -13 -10 -13 -16
0 -6 5 -8 10 -5 6 3 10 2 10 -4 0 -12 -7 -16 -42 -25 -21 -5 -28 -3 -28 9 0 8
5 15 10 15 6 0 10 13 10 29 0 16 7 34 15 41 8 7 15 18 15 26 0 7 6 19 13 26 6
7 21 29 31 48 11 19 25 38 32 43 7 4 15 21 19 37 4 17 11 28 15 25 8 -5 36 33
28 40 -21 18 -50 24 -67 15 -11 -5 -27 -10 -36 -10 -14 0 -14 2 0 24 11 16 12
25 5 28 -19 6 0 36 19 31 26 -7 36 36 13 53 -24 17 -6 31 20 15 17 -11 22 -11
29 1 5 7 9 17 9 21 0 5 4 6 9 3 5 -3 13 13 19 35 8 33 7 44 -5 59 -9 10 -23
16 -32 14 -9 -2 -5 1 9 8 19 10 25 20 23 41 -1 15 2 27 6 27 5 0 7 12 5 28 -2
15 -2 22 2 15 3 -7 12 -9 20 -6 10 4 14 -3 14 -27 0 -24 -4 -31 -15 -26 -12 4
-15 -3 -15 -36 l0 -41 23 26 c12 15 30 27 40 27 13 0 17 8 15 40 -1 26 5 47
16 59 10 11 15 25 10 32 -4 8 -3 10 5 5 6 -4 14 0 18 10 6 16 7 16 14 -2 5
-12 8 -1 8 34 1 38 -3 52 -13 52 -15 0 -26 -21 -26 -51 0 -11 -4 -18 -9 -14
-14 8 -24 -13 -23 -47 1 -25 -2 -28 -21 -22 -28 9 -40 26 -24 36 9 6 9 8 0 8
-7 0 -19 13 -28 30 -17 32 -19 44 -7 37 4 -3 8 13 9 36 2 22 7 43 11 46 14 10
42 90 33 95 -5 3 -18 -21 -30 -52z m-18 -182 c3 -11 1 -20 -4 -20 -5 0 -9 9
-9 20 0 11 2 20 4 20 2 0 6 -9 9 -20z m67 -120 c0 -5 -5 -10 -11 -10 -5 0 -7
5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-100 -140 c0 -11 -4 -20 -10 -20 -5
0 -10 5 -10 10 0 6 -7 10 -16 10 -8 0 -12 5 -9 10 12 19 45 11 45 -10z m390
-850 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M8185 6170 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8132 6084 c-7 -8 -8 -14 -3 -14 10 0 25 19 20 25 -2 1 -10 -3 -17
-11z"/>
<path d="M5280 6079 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7454 6043 c-4 -18 -11 -33 -16 -33 -5 0 -6 -4 -3 -10 11 -18 23 -11
33 19 6 16 6 34 1 42 -6 10 -10 5 -15 -18z"/>
<path d="M7940 6010 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7422 5940 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7407 5903 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M7374 5862 c-6 -4 -14 -25 -17 -47 -4 -21 -16 -52 -27 -67 -11 -15
-20 -34 -20 -41 0 -8 -7 -28 -15 -43 -8 -16 -15 -37 -15 -46 0 -10 -4 -18 -9
-18 -5 0 -8 -11 -8 -25 0 -48 5 -39 81 124 8 18 18 49 22 69 4 19 13 47 20 61
21 39 16 52 -12 33z"/>
<path d="M8100 5691 c0 -5 5 -11 11 -13 7 -2 -2 -18 -20 -35 -17 -17 -36 -44
-42 -60 -6 -15 -13 -22 -16 -15 -3 6 -10 12 -16 12 -8 0 -7 -4 1 -12 7 -7 12
-18 12 -25 0 -21 -20 -15 -29 9 l-8 22 -6 -23 c-3 -13 -19 -27 -36 -34 -34
-13 -41 -27 -13 -27 15 -1 15 -2 -2 -15 -11 -8 -17 -18 -14 -21 11 -11 81 21
86 39 3 14 7 15 18 6 11 -9 15 -5 20 20 4 17 13 31 20 31 8 0 11 6 7 16 -3 10
0 22 8 28 28 21 59 74 53 90 -6 17 -34 22 -34 7z m-100 -191 c0 -5 -9 -10 -20
-10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z"/>
<path d="M7165 5570 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z"/>
<path d="M7236 5524 c-11 -28 -6 -39 9 -20 8 11 15 23 15 28 0 14 -18 9 -24
-8z"/>
<path d="M7950 5410 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M7900 5382 c0 -7 -10 -17 -22 -21 -22 -9 -22 -9 -4 -17 13 -5 21 -3
24 4 6 18 22 14 22 -5 0 -13 3 -14 11 -6 8 8 6 17 -10 34 -11 13 -21 17 -21
11z"/>
<path d="M7200 5246 c0 -15 -7 -26 -19 -29 -15 -3 -18 -11 -13 -33 3 -16 10
-37 17 -47 11 -15 12 -11 9 30 -3 35 -2 43 5 28 8 -16 10 -10 10 28 1 26 -1
47 -4 47 -3 0 -5 -11 -5 -24z"/>
<path d="M5105 5180 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M7210 5161 c0 -39 101 -211 124 -211 5 0 -2 12 -16 28 -14 15 -44 65
-67 111 -22 46 -41 78 -41 72z"/>
<path d="M7538 5038 c-24 -30 -22 -48 5 -48 29 0 53 36 36 56 -15 19 -21 18
-41 -8z"/>
<path d="M5424 5010 c-37 -15 -36 -70 2 -70 7 0 14 -6 17 -12 3 -10 10 -8 24
5 18 16 19 23 10 53 -11 35 -18 39 -53 24z"/>
<path d="M7335 4990 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5455 4870 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M7520 4832 c0 -6 -9 -14 -20 -17 -19 -6 -19 -25 0 -25 12 0 33 40 26
48 -3 3 -6 0 -6 -6z"/>
<path d="M7423 4802 c-3 -14 -15 -41 -26 -59 -17 -29 -17 -33 -3 -33 9 0 24 9
33 19 9 11 21 17 26 14 5 -3 13 1 18 8 8 12 11 12 19 -1 9 -13 11 -13 20 0 5
8 10 20 10 25 0 6 -4 4 -9 -4 -7 -11 -11 -10 -20 5 -11 19 -11 19 -22 0 -9
-15 -13 -16 -20 -5 -12 19 -11 32 1 24 6 -3 10 0 10 8 0 8 -7 17 -15 21 -10 3
-17 -3 -22 -22z"/>
<path d="M5460 5560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5010 4876 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M6026 4464 c-3 -9 -6 -23 -6 -32 0 -13 3 -12 15 4 16 21 19 44 6 44
-5 0 -12 -7 -15 -16z"/>
<path d="M6636 4305 c7 -36 9 -39 25 -29 9 5 8 10 -3 18 -8 6 -18 18 -21 26
-4 8 -4 2 -1 -15z"/>
<path d="M6590 4300 c0 -11 -5 -20 -12 -20 -6 0 -9 -2 -6 -5 3 -3 13 -3 22 1
17 7 23 44 6 44 -5 0 -10 -9 -10 -20z"/>
<path d="M6525 4280 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M7018 4273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6495 4230 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3697 10105 c-31 -38 -57 -75 -57 -83 0 -8 71 -86 158 -174 86 -88
159 -162 162 -165 5 -6 -63 40 -271 184 -59 40 -112 73 -117 73 -6 0 -28 -21
-49 -47 -21 -26 -48 -59 -60 -73 -13 -14 -23 -28 -23 -33 0 -4 72 -80 160
-170 88 -89 160 -163 160 -165 0 -4 -235 157 -314 215 -32 24 -61 43 -65 43
-3 0 -28 -28 -56 -63 -27 -34 -53 -63 -57 -65 -22 -10 3 -33 97 -92 226 -139
291 -181 308 -195 9 -8 20 -15 23 -15 4 0 33 -18 65 -40 32 -22 63 -40 69 -40
5 0 10 -3 10 -7 0 -5 14 -16 31 -26 36 -20 33 -22 99 63 19 25 47 58 62 74 15
17 24 35 20 42 -4 7 -38 45 -76 85 -38 41 -91 98 -118 127 -27 28 -47 52 -44
52 3 0 70 -38 148 -85 79 -47 152 -85 163 -85 21 1 155 158 155 182 -1 7 -119
134 -264 282 l-263 270 -56 -69z"/>
<path d="M8526 9961 c-25 -33 -46 -61 -46 -64 0 -3 25 -23 55 -46 l56 -42 19
23 c11 13 32 40 47 62 l27 38 -29 25 c-17 13 -42 33 -56 43 l-26 20 -47 -59z"/>
<path d="M9538 9761 c-58 -19 -364 -234 -421 -296 -60 -66 -59 -158 3 -253 50
-77 111 -116 182 -114 60 1 106 23 218 102 47 33 94 65 104 72 10 7 52 39 93
71 84 66 108 106 106 179 -1 46 -37 133 -71 172 -50 56 -150 87 -214 67z m126
-176 c3 -8 3 -21 1 -27 -8 -21 -383 -283 -409 -286 -32 -4 -39 21 -13 46 35
33 391 281 403 282 7 0 14 -7 18 -15z"/>
<path d="M3116 9319 c-21 -45 -35 -86 -33 -93 5 -12 270 -178 344 -215 68 -34
59 -43 -14 -14 -37 14 -90 34 -118 45 -27 10 -98 36 -156 58 l-107 38 -41 -87
c-22 -47 -41 -91 -41 -98 0 -6 85 -62 189 -125 104 -62 188 -114 186 -116 -2
-2 -94 31 -205 72 -112 42 -206 76 -211 76 -9 0 -79 -145 -79 -164 0 -11 717
-254 723 -245 14 19 97 203 97 214 0 7 -26 29 -57 49 -95 57 -258 166 -251
166 4 0 25 -7 45 -15 55 -21 282 -85 302 -85 11 0 32 31 64 95 26 52 47 102
47 111 0 15 -37 53 -52 54 -7 0 -484 293 -548 336 -19 13 -37 24 -41 24 -3 0
-23 -36 -43 -81z"/>
<path d="M9825 9189 c-99 -45 -250 -112 -335 -151 -85 -38 -159 -73 -164 -78
-4 -4 3 -33 18 -64 14 -30 26 -58 26 -63 0 -4 4 -14 8 -21 9 -13 23 -8 192 70
152 71 259 112 195 76 -11 -6 -69 -43 -130 -83 -60 -39 -134 -86 -163 -104
-57 -36 -56 -35 -26 -108 10 -22 21 -33 35 -33 11 0 95 23 187 51 267 82 265
76 -17 -51 -79 -35 -145 -69 -147 -75 -4 -9 49 -140 61 -153 2 -2 82 33 179
77 97 45 179 81 181 81 2 0 41 17 87 39 46 21 120 55 165 76 l81 37 -13 37
c-11 29 -83 191 -89 198 -2 2 -127 -34 -226 -66 -72 -23 -108 -27 -75 -10 11
6 36 21 55 34 19 12 72 46 118 75 72 46 83 56 78 76 -7 25 -93 214 -98 214 -2
0 -84 -37 -183 -81z"/>
<path d="M2863 8373 c-18 -60 -35 -121 -39 -138 -12 -54 -15 -55 -183 -55
-141 -1 -154 -2 -136 -16 11 -8 29 -23 41 -33 16 -14 164 -125 183 -138 2 -1
-15 -73 -37 -160 -23 -87 -39 -159 -38 -161 2 -1 57 40 122 92 66 53 121 96
124 96 3 0 56 -40 117 -90 62 -49 117 -90 123 -90 7 0 9 8 4 22 -22 72 -64
245 -64 268 0 23 16 39 97 101 54 41 106 81 116 89 16 13 2 16 -140 20 l-157
5 -13 35 c-6 19 -26 86 -44 148 -18 61 -35 112 -39 112 -3 0 -20 -48 -37 -107z"/>
<path d="M10096 8428 c-8 -29 -25 -89 -37 -133 -34 -125 -16 -115 -196 -115
-180 0 -177 10 -33 -100 47 -35 91 -70 98 -77 22 -21 18 -48 -37 -243 -12 -41
-21 -76 -21 -77 0 -14 38 11 132 87 61 50 114 90 117 90 3 0 21 -13 40 -28 19
-15 69 -56 113 -91 43 -35 81 -61 85 -58 3 4 -11 73 -31 154 -40 165 -40 163
-25 163 6 0 16 6 23 13 7 6 54 44 104 82 50 39 91 73 91 78 1 4 -66 7 -148 7
-107 0 -151 3 -158 13 -5 6 -27 74 -49 150 -21 75 -42 137 -46 137 -3 0 -13
-24 -22 -52z"/>
<path d="M2738 7528 c-3 -22 -8 -23 -118 -28 -63 -3 -114 -8 -112 -12 1 -5 2
-21 2 -38 l0 -30 115 0 c108 0 115 -1 115 -20 0 -16 7 -20 30 -20 34 0 35 3
24 109 -6 55 -9 61 -30 61 -16 0 -24 -7 -26 -22z"/>
<path d="M10308 7524 c-4 -8 -4 -16 0 -51 1 -9 21 -13 55 -14 l52 -1 -52 -15
c-44 -13 -52 -19 -55 -45 -3 -16 -2 -30 1 -32 3 -2 67 -5 141 -7 l135 -4 3 33
3 32 -58 1 -58 1 63 15 c61 16 62 17 62 50 l0 33 -77 0 c-43 0 -108 3 -144 6
-36 4 -68 3 -71 -2z"/>
<path d="M2605 7351 l-80 -6 0 -65 c0 -63 1 -65 25 -65 20 0 26 6 28 26 5 41
62 48 62 8 0 -14 7 -19 30 -19 25 0 30 4 30 25 0 20 5 25 25 25 20 0 25 -5 25
-25 0 -20 5 -25 24 -25 34 0 39 13 31 76 l-6 54 -57 -2 c-31 -1 -93 -4 -137
-7z"/>
<path d="M10340 7313 c-36 -13 -50 -36 -50 -83 0 -68 19 -81 132 -91 51 -4
103 -5 115 -2 47 11 65 112 27 150 -7 7 -55 17 -106 23 -103 11 -97 11 -118 3z
m189 -89 c13 -13 -18 -15 -98 -9 -51 4 -91 12 -91 17 0 10 179 2 189 -8z"/>
<path d="M2685 7199 c-33 -4 -80 -9 -105 -10 -25 -1 -47 -6 -50 -10 -3 -5 -3
-32 0 -61 5 -48 7 -53 30 -53 17 0 26 7 30 25 3 14 10 25 15 26 90 5 194 17
204 23 7 5 9 19 5 39 -7 36 -13 37 -129 21z"/>
<path d="M10276 7078 c-7 -37 4 -48 54 -48 31 0 98 -9 196 -25 29 -5 32 -3 36
25 2 16 2 34 -1 39 -6 9 -205 41 -255 41 -18 0 -25 -7 -30 -32z"/>
<path d="M2740 7048 c-152 -22 -195 -33 -193 -48 12 -106 13 -111 41 -108 23
2 27 7 24 29 -2 20 3 28 20 33 33 8 34 8 46 -18 13 -30 49 -25 54 8 4 29 43
34 51 7 3 -15 13 -21 31 -21 24 0 26 4 25 38 -1 47 -12 85 -24 87 -6 1 -39 -2
-75 -7z"/>
<path d="M10266 6978 c-9 -12 -16 -41 -16 -64 0 -52 25 -76 79 -78 34 0 47 5
79 37 38 37 82 49 82 23 0 -10 -9 -14 -27 -13 -21 2 -28 -4 -34 -26 -6 -24 -4
-30 16 -39 38 -17 74 -2 91 37 33 80 6 125 -76 125 -45 0 -54 -4 -81 -35 -29
-33 -79 -49 -79 -25 0 6 11 10 25 10 22 0 25 4 25 35 0 33 -2 35 -34 35 -25 0
-39 -7 -50 -22z"/>
<path d="M2660 6876 c0 -7 3 -28 6 -45 5 -24 12 -31 30 -31 25 0 28 8 18 59
-5 24 -12 31 -30 31 -14 0 -24 -6 -24 -14z"/>
<path d="M10243 6802 c-16 -10 -33 -57 -33 -91 0 -36 29 -62 77 -67 33 -4 43
0 78 31 26 23 48 35 63 33 30 -4 24 -22 -7 -22 -18 -1 -26 -8 -32 -28 -9 -34
-4 -38 43 -38 49 0 69 19 75 73 7 58 -18 87 -77 94 -41 4 -49 1 -83 -31 -38
-35 -70 -46 -82 -26 -3 6 5 10 18 10 23 0 36 20 37 58 0 13 -57 17 -77 4z"/>
<path d="M2719 6767 c-47 -12 -93 -30 -102 -39 -23 -22 -21 -76 3 -115 17 -28
26 -33 57 -33 54 0 67 11 58 50 -7 30 -9 32 -42 25 -27 -5 -34 -3 -31 7 4 13
120 46 166 47 36 1 25 -16 -15 -26 -35 -8 -38 -12 -35 -38 2 -17 7 -34 12 -39
11 -12 74 11 95 34 26 28 15 98 -20 127 -32 28 -38 28 -146 0z"/>
<path d="M10185 6600 c-4 -18 -3 -36 1 -40 10 -10 193 -61 240 -67 29 -5 33
-2 39 27 4 17 5 33 2 36 -3 4 -180 53 -253 71 -18 4 -23 -1 -29 -27z"/>
<path d="M2765 6554 c-44 -14 -86 -33 -92 -42 -23 -28 -16 -96 12 -122 21 -20
31 -22 74 -16 67 9 163 43 179 63 21 29 15 91 -13 118 -30 31 -58 31 -160 -1z
m135 -54 c0 -5 -28 -18 -62 -28 -95 -29 -128 -35 -128 -23 0 6 3 11 8 11 4 1
43 12 87 25 93 28 95 28 95 15z"/>
<path d="M10157 6498 c-3 -13 -9 -33 -12 -46 -6 -19 -1 -25 39 -43 25 -11 46
-22 46 -25 0 -5 -43 1 -82 12 -18 5 -29 -14 -43 -69 -6 -22 2 -26 130 -66 74
-24 138 -42 141 -39 3 3 6 18 7 34 2 26 -2 29 -68 50 -59 19 -63 22 -25 16 80
-12 114 -9 118 10 2 12 -14 26 -55 48 -55 29 -47 32 25 7 30 -10 36 -9 48 8
20 27 14 47 -16 56 -14 4 -70 21 -125 38 -55 17 -105 31 -111 31 -7 0 -14 -10
-17 -22z"/>
<path d="M2838 6345 c-146 -54 -140 -50 -126 -80 13 -29 21 -30 78 -10 60 21
60 13 0 -15 -49 -23 -56 -33 -44 -63 4 -12 13 -12 62 -1 81 19 86 16 17 -11
-51 -20 -60 -27 -57 -46 1 -13 6 -27 10 -31 7 -7 256 73 268 85 4 3 -26 97
-29 97 -1 0 -26 -7 -55 -14 -60 -16 -61 -8 -2 19 22 10 40 25 40 33 0 27 -24
82 -35 81 -5 0 -63 -20 -127 -44z"/>
<path d="M10083 6245 c-13 -9 -28 -34 -34 -56 -11 -35 -10 -43 7 -66 34 -46
67 -50 132 -16 59 30 92 33 92 6 0 -15 -3 -14 -45 3 -6 2 -16 -10 -23 -28 -14
-39 -5 -48 51 -48 31 0 40 5 57 33 11 17 20 47 20 65 0 26 -6 36 -32 52 -44
27 -86 25 -131 -6 -39 -26 -67 -29 -75 -6 -3 9 3 11 21 7 21 -6 26 -2 35 25
10 28 9 32 -14 40 -33 13 -36 13 -61 -5z"/>
<path d="M3020 6125 c-14 -8 -47 -23 -75 -34 -27 -10 -72 -29 -98 -40 l-48
-21 16 -30 16 -30 37 16 c67 29 78 27 27 -6 -27 -18 -51 -34 -53 -35 -2 -2 1
-12 7 -24 11 -19 13 -19 62 -5 67 20 72 12 9 -15 -27 -12 -50 -24 -50 -27 0
-5 19 -45 25 -52 1 -1 33 11 71 27 199 83 201 85 167 142 -13 22 -23 29 -38
24 -11 -3 -31 -8 -45 -11 -27 -5 -29 -7 34 39 18 13 18 16 4 53 -18 44 -32 50
-68 29z"/>
<path d="M10000 6061 c-15 -28 -15 -28 38 -56 l47 -24 -56 6 c-51 5 -58 3 -68
-15 -21 -38 -19 -39 149 -112 88 -39 95 -40 107 -24 21 29 15 41 -27 58 -54
21 -50 29 9 21 43 -6 51 -4 60 14 6 11 11 26 11 33 0 12 -223 118 -248 118 -6
0 -16 -9 -22 -19z"/>
<path d="M3065 5850 c-116 -62 -128 -74 -122 -125 6 -43 44 -85 76 -85 21 0
217 95 226 109 8 14 -16 61 -33 61 -8 0 -56 -22 -108 -50 -100 -53 -104 -55
-104 -41 0 5 45 32 100 61 101 51 110 62 90 101 -14 26 -21 24 -125 -31z"/>
<path d="M9892 5835 c-12 -25 -22 -47 -22 -49 0 -3 188 -135 227 -159 8 -5 18
3 28 24 16 30 16 32 -7 56 l-23 25 33 -3 c27 -3 35 1 47 25 9 16 12 33 8 36
-8 8 -249 90 -262 90 -4 0 -17 -20 -29 -45z m164 -76 c10 -16 -21 -9 -46 11
l-25 20 33 -11 c17 -6 35 -15 38 -20z"/>
<path d="M3145 5645 c-60 -36 -111 -71 -113 -78 -1 -7 4 -21 11 -31 14 -19 15
-19 51 3 47 30 54 23 16 -14 -33 -32 -37 -50 -15 -79 13 -17 19 -15 122 44
133 76 139 81 124 109 -14 26 -21 26 -65 0 -45 -26 -46 -15 -4 28 29 30 31 35
18 58 -7 14 -18 25 -24 25 -6 -1 -60 -30 -121 -65z"/>
<path d="M9820 5688 c-23 -24 -64 -97 -58 -103 2 -2 57 -35 123 -73 l120 -70
17 27 c26 38 23 46 -22 66 -22 10 -45 24 -51 32 -19 22 8 16 58 -14 44 -26 46
-26 59 -8 8 10 14 26 14 34 0 19 -95 75 -115 67 -8 -3 -16 3 -19 12 -7 22 -57
52 -85 52 -11 0 -30 -10 -41 -22z m61 -58 c19 -11 26 -30 10 -30 -5 0 -19 9
-32 20 -26 21 -11 27 22 10z"/>
<path d="M9701 5503 l-42 -66 21 -21 c20 -20 22 -20 34 -3 7 9 16 17 20 17 3
0 44 -25 90 -55 46 -30 88 -55 94 -55 11 0 42 38 42 52 0 4 -41 33 -90 65 -73
47 -90 62 -86 78 5 18 -18 55 -34 55 -3 0 -25 -30 -49 -67z"/>
<path d="M3349 5522 c-14 -10 -67 -43 -117 -76 -51 -32 -92 -61 -92 -65 0 -13
31 -51 42 -51 12 0 181 103 211 128 9 8 17 21 17 27 0 7 -8 22 -17 33 -17 19
-19 19 -44 4z"/>
<path d="M3344 5381 c-32 -21 -72 -53 -88 -69 -24 -26 -28 -37 -23 -67 11 -64
71 -99 125 -71 42 22 44 28 17 62 -22 28 -24 28 -42 11 -20 -18 -43 -23 -43
-9 0 9 119 98 144 108 25 10 19 -14 -9 -32 -30 -19 -31 -31 -6 -63 l18 -24 32
22 c17 12 36 31 42 42 23 44 -33 129 -85 129 -12 0 -49 -18 -82 -39z"/>
<path d="M9559 5297 c-14 -17 -28 -37 -32 -43 -3 -6 40 -56 96 -112 l103 -103
23 32 c22 29 22 31 5 50 -22 24 -10 38 18 20 18 -11 23 -10 44 16 13 15 23 30
24 33 0 4 -222 127 -249 137 -3 2 -18 -12 -32 -30z m122 -96 c13 -11 21 -22
17 -26 -4 -4 -19 5 -35 19 -32 30 -17 36 18 7z"/>
<path d="M3451 5184 c-57 -35 -107 -64 -112 -64 -15 0 -9 -24 12 -49 18 -22
23 -23 41 -12 26 16 46 0 23 -19 -21 -17 -19 -33 8 -58 l23 -22 97 105 97 106
-32 39 c-17 22 -36 40 -42 39 -6 0 -58 -29 -115 -65z m49 -69 c-12 -13 -27
-22 -33 -18 -6 4 -1 14 12 25 34 26 48 22 21 -7z"/>
<path d="M9439 5148 l-51 -62 22 -18 c20 -16 25 -17 39 -4 15 14 25 8 101 -54
46 -39 87 -70 91 -70 10 0 39 41 39 55 -1 5 -37 39 -80 74 -77 63 -92 83 -70
96 13 8 5 22 -20 35 -16 9 -27 1 -71 -52z"/>
<path d="M3639 5114 c-10 -12 -10 -20 -1 -34 9 -16 1 -26 -61 -81 -40 -34 -78
-67 -84 -73 -10 -9 -7 -17 11 -39 13 -15 25 -27 28 -27 3 0 41 31 84 70 79 70
99 81 106 58 5 -15 37 -3 44 17 7 16 -81 125 -101 125 -7 0 -18 -7 -26 -16z"/>
<path d="M9326 5019 c-20 -17 -36 -36 -36 -43 0 -15 169 -216 182 -216 10 0
48 38 48 47 0 2 -5 12 -12 23 -16 25 -5 35 21 19 17 -12 24 -10 46 11 20 19
23 26 13 35 -12 11 -188 132 -215 148 -7 4 -27 -6 -47 -24z m113 -100 c14 -11
20 -23 15 -28 -5 -5 -19 4 -32 20 -27 33 -18 38 17 8z"/>
<path d="M3683 4896 l-97 -93 17 -27 c10 -14 22 -26 28 -26 11 0 201 176 207
192 4 9 -37 47 -50 48 -4 0 -51 -42 -105 -94z"/>
<path d="M9175 4859 c-25 -23 -45 -46 -45 -51 0 -14 181 -198 195 -198 19 0
105 95 105 116 0 11 -33 52 -78 97 -94 93 -111 97 -177 36z m120 -89 c32 -33
56 -63 52 -67 -8 -7 -127 105 -127 119 0 18 19 5 75 -52z"/>
<path d="M3767 4811 c-42 -43 -77 -86 -77 -96 0 -27 31 -72 57 -84 50 -23 71
-14 140 57 36 37 69 77 74 90 11 29 -3 64 -37 91 -48 38 -73 29 -157 -58z m68
-67 c-75 -75 -101 -80 -35 -7 61 68 94 96 98 84 2 -6 -26 -40 -63 -77z"/>
<path d="M3969 4716 c-133 -150 -147 -176 -103 -193 12 -4 26 4 45 25 39 43
43 40 17 -13 -21 -43 -21 -45 -3 -65 24 -27 39 -25 64 7 12 14 45 53 74 86 83
95 83 95 58 120 l-22 22 -35 -40 c-30 -34 -34 -36 -28 -15 3 14 13 37 20 51
12 24 11 28 -10 48 -12 11 -24 21 -27 20 -2 -1 -25 -24 -50 -53z"/>
<path d="M8965 4668 c-22 -18 -43 -37 -47 -42 -5 -8 90 -130 167 -214 10 -11
17 -9 41 11 l28 25 -37 45 c-52 63 -46 83 7 23 44 -50 44 -50 70 -33 14 10 26
21 26 25 0 15 -70 91 -90 97 -11 4 -20 14 -20 24 0 25 -52 71 -81 71 -13 0
-42 -14 -64 -32z m82 -74 c-10 -10 -37 16 -37 34 0 13 4 12 22 -5 12 -13 19
-25 15 -29z"/>
<path d="M8801 4544 c-46 -39 -42 -72 20 -155 82 -111 116 -124 176 -64 46 46
41 70 -37 169 -64 83 -105 96 -159 50z m105 -109 c52 -69 65 -95 38 -77 -10 7
-93 115 -108 140 -4 6 -3 12 3 12 6 0 36 -34 67 -75z"/>
<path d="M4198 4533 c-49 -54 -108 -147 -108 -171 0 -29 53 -81 84 -83 38 -2
51 2 74 26 27 29 27 37 4 58 -25 23 -40 21 -62 -5 -23 -29 -38 -17 -18 14 39
60 100 128 109 123 5 -4 0 -20 -13 -37 l-22 -31 28 -24 28 -24 24 32 c33 46
31 84 -7 120 -41 39 -86 39 -121 2z"/>
<path d="M4388 4428 c-28 -22 -118 -169 -118 -192 0 -27 46 -72 82 -81 40 -10
65 9 119 92 56 87 63 127 25 162 -29 27 -85 37 -108 19z m15 -143 c-43 -68
-74 -98 -59 -60 14 37 100 158 104 147 2 -7 -18 -46 -45 -87z"/>
<path d="M8603 4402 c-24 -15 -43 -31 -43 -35 0 -5 34 -60 76 -123 l76 -115
36 22 c64 39 72 49 59 70 -8 14 -19 18 -34 14 -12 -3 -29 -2 -37 1 -20 8 -21
40 0 52 13 7 13 11 -4 32 -17 21 -22 23 -37 10 -14 -12 -18 -12 -26 1 -13 21
-11 37 7 47 13 7 14 13 5 31 -15 27 -27 26 -78 -7z"/>
<path d="M4538 4276 c-106 -181 -108 -186 -61 -200 18 -6 26 1 49 44 35 64 45
56 14 -10 -22 -48 -23 -51 -6 -68 16 -16 19 -14 62 33 l45 50 -25 -46 c-14
-25 -26 -53 -26 -62 0 -22 39 -33 55 -16 17 18 138 229 134 233 -11 7 -81 46
-84 46 -2 0 -17 -19 -34 -42 -34 -47 -38 -45 -16 9 8 19 15 41 15 47 0 12 -54
46 -73 46 -7 0 -29 -29 -49 -64z"/>
<path d="M8453 4315 c-34 -15 -53 -41 -53 -76 0 -23 46 -111 87 -166 29 -39
70 -44 121 -13 28 17 32 25 32 63 0 24 -6 51 -13 61 -13 17 -15 17 -45 2 -37
-19 -37 -18 -17 -56 18 -35 2 -51 -17 -18 -7 13 -17 30 -23 39 -24 39 -55 99
-55 109 0 19 16 10 33 -20 21 -34 33 -37 64 -12 l22 17 -21 33 c-18 29 -54 53
-76 52 -4 -1 -21 -7 -39 -15z"/>
<path d="M8303 4228 c-13 -6 -23 -17 -23 -25 0 -11 111 -224 126 -242 6 -6 64
21 64 30 0 5 -27 59 -60 121 -33 61 -60 115 -60 120 0 11 -20 9 -47 -4z"/>
<path d="M4750 4095 c-30 -63 -56 -121 -58 -127 -3 -18 48 -40 63 -28 7 5 20
27 29 49 17 37 19 38 47 27 38 -14 57 -2 87 53 32 57 24 81 -37 114 -27 15
-55 27 -62 26 -8 0 -39 -52 -69 -114z m120 23 c-1 -17 -28 -53 -35 -46 -8 7
16 58 27 58 4 0 8 -6 8 -12z"/>
<path d="M8155 4158 c-56 -30 -57 -65 -9 -167 44 -94 59 -111 96 -111 55 0 97
35 98 82 0 29 -59 159 -86 189 -19 21 -68 25 -99 7z m80 -126 c36 -75 41 -92
26 -92 -9 0 -81 152 -81 172 0 22 18 -5 55 -80z"/>
<path d="M4947 4043 c-25 -43 -58 -97 -72 -120 l-26 -42 30 -16 c39 -20 48
-19 56 5 10 30 25 24 25 -10 0 -23 7 -33 30 -45 26 -13 31 -14 36 -2 8 21 73
271 71 272 -1 0 -19 8 -40 18 -21 9 -44 17 -51 17 -7 0 -34 -35 -59 -77z m55
-68 c-4 -25 -19 -41 -28 -32 -5 5 24 57 31 57 1 0 0 -11 -3 -25z"/>
<path d="M8033 4098 c-28 -14 -29 -28 -3 -97 11 -30 18 -57 15 -59 -2 -2 -16
20 -31 50 -38 76 -40 78 -75 66 -40 -14 -43 -23 -21 -55 11 -16 41 -65 67
-110 26 -46 53 -87 59 -92 12 -11 106 25 106 40 0 5 -9 44 -21 87 -52 196 -50
192 -96 170z"/>
<path d="M5152 4046 c-12 -15 -92 -233 -92 -250 0 -11 28 -25 51 -26 3 0 12
19 20 43 l14 42 3 -52 c3 -47 6 -52 32 -62 16 -5 31 -7 34 -3 2 4 25 63 50
132 43 116 45 126 29 137 -28 21 -40 15 -61 -34 l-21 -48 1 51 c1 27 -1 55 -5
61 -10 16 -45 22 -55 9z"/>
<path d="M5337 3983 c-4 -3 -7 -43 -7 -89 0 -49 -7 -102 -17 -133 -18 -56 -14
-66 28 -76 24 -6 27 -3 34 32 7 38 45 107 97 174 20 27 24 39 16 47 -22 22
-57 14 -73 -18 -21 -41 -26 -38 -19 10 6 36 4 40 -20 50 -32 12 -31 12 -39 3z"/>
<path d="M7668 3963 c-21 -9 -38 -21 -38 -26 0 -6 17 -66 38 -134 31 -103 40
-123 57 -123 26 0 105 31 105 41 0 24 -15 39 -40 39 -20 0 -29 6 -33 22 -7 26
-1 38 20 38 10 0 13 6 9 16 -3 9 -6 19 -6 24 0 5 -12 10 -27 12 -36 4 -45 43
-13 53 14 4 19 12 16 22 -3 8 -6 19 -6 24 0 13 -39 9 -82 -8z"/>
<path d="M7473 3907 c-24 -5 -43 -15 -43 -21 0 -6 14 -68 32 -138 28 -108 36
-128 52 -128 36 0 127 34 136 50 10 19 -31 191 -55 227 -17 26 -48 28 -122 10z
m82 -126 c22 -90 15 -126 -9 -46 -24 82 -32 130 -19 122 6 -4 18 -38 28 -76z"/>
<path d="M5704 3874 c-2 -8 -6 -20 -8 -28 -3 -8 4 -16 15 -19 24 -6 24 -16 -1
-129 -12 -50 -17 -94 -13 -98 4 -4 20 -10 35 -14 25 -6 28 -3 39 36 6 24 17
72 23 108 11 60 14 65 39 68 22 3 27 8 27 31 0 27 -3 29 -76 43 -64 13 -76 13
-80 2z"/>
<path d="M7333 3873 c-18 -7 -16 -45 6 -133 10 -41 21 -92 25 -112 7 -39 22
-47 60 -32 20 8 20 31 2 108 -8 34 -20 87 -26 119 -10 49 -14 57 -33 56 -12 0
-28 -3 -34 -6z"/>
<path d="M7237 3853 c-14 -4 -17 -12 -13 -36 8 -49 16 -143 11 -132 -2 6 -13
43 -24 83 -21 71 -22 72 -56 72 -18 0 -36 -4 -39 -8 -4 -7 78 -270 87 -279 2
-1 27 4 56 10 l54 12 -2 90 c-1 50 -4 114 -7 143 -7 54 -13 59 -67 45z"/>
<path d="M5860 3709 c-12 -77 -20 -142 -18 -143 1 -2 18 -6 36 -9 37 -7 37 -6
47 66 9 68 25 58 22 -15 l-2 -63 32 -3 c37 -4 34 -12 58 146 21 142 21 139
-20 144 -35 4 -35 4 -41 -44 -8 -61 -26 -63 -23 -3 3 51 4 50 -37 58 l-31 6
-23 -140z"/>
<path d="M6109 3817 c-27 -97 -65 -274 -60 -280 5 -5 24 -7 42 -5 27 2 35 8
37 26 4 23 26 26 28 4 1 -7 2 -19 3 -27 1 -10 12 -15 37 -15 l36 0 -7 138 c-4
75 -10 140 -13 143 -6 6 -101 21 -103 16z m47 -185 c-13 -13 -17 9 -10 50 l9
43 3 -44 c2 -23 1 -46 -2 -49z"/>
<path d="M6957 3809 c-10 -6 -22 -20 -26 -32 -10 -26 4 -170 20 -210 13 -32
59 -48 104 -35 59 16 65 38 49 180 -8 79 -32 108 -87 108 -23 0 -50 -5 -60
-11z m67 -76 c14 -102 17 -153 8 -153 -10 0 -16 28 -28 133 -7 64 11 82 20 20z"/>
<path d="M6267 3793 c-4 -3 -7 -17 -7 -30 0 -16 6 -23 20 -23 18 0 20 -8 22
-117 l3 -117 30 0 c28 0 30 3 37 44 3 25 7 75 7 112 1 61 3 67 24 70 15 2 23
11 25 29 3 24 -1 27 -44 33 -60 8 -109 8 -117 -1z"/>
<path d="M6715 3790 c-7 -11 11 -266 20 -279 4 -7 25 -8 58 -2 4 0 7 31 7 68
0 80 17 75 22 -7 l3 -55 35 0 35 0 -3 60 c-3 36 -10 67 -20 78 -9 10 -11 17
-4 17 20 0 15 94 -6 113 -21 19 -136 24 -147 7z m95 -76 c0 -13 -4 -24 -10
-24 -5 0 -10 14 -10 31 0 17 4 28 10 24 6 -3 10 -17 10 -31z"/>
<path d="M6522 3649 l3 -144 38 -3 37 -3 0 54 c0 51 2 55 27 62 45 11 53 24
53 84 0 79 -6 85 -90 89 l-71 4 3 -143z m98 50 c0 -16 -4 -29 -10 -29 -5 0
-10 16 -10 36 0 21 4 33 10 29 6 -3 10 -19 10 -36z"/>
<path d="M5516 3678 c-17 -54 -16 -68 3 -68 22 0 41 27 41 59 0 23 -38 30 -44
9z"/>
<path d="M3653 2930 c-24 -14 -36 -32 -47 -71 -19 -62 -19 -81 -6 -130 16 -59
54 -89 111 -89 81 0 129 60 129 163 0 115 -97 182 -187 127z m97 -66 c14 -35
12 -114 -3 -144 -17 -33 -43 -37 -58 -8 -18 32 -16 157 2 169 26 16 49 9 59
-17z"/>
<path d="M3920 2932 c-44 -22 -54 -71 -21 -106 l21 -22 -25 -24 c-25 -23 -32
-60 -19 -95 10 -25 58 -45 109 -45 38 0 52 5 76 29 36 36 37 70 4 110 l-26 30
21 21 c23 23 25 39 9 73 -21 47 -88 60 -149 29z m80 -57 c0 -7 -6 -18 -14 -24
-12 -10 -17 -8 -25 7 -7 13 -7 22 1 30 13 13 38 5 38 -13z m0 -134 c13 -25 3
-51 -20 -51 -25 0 -33 16 -26 46 8 29 31 32 46 5z"/>
<path d="M4182 2930 c-56 -34 -77 -121 -52 -212 26 -94 169 -106 215 -18 21
41 20 163 -1 196 -36 54 -105 68 -162 34z m88 -59 c16 -32 13 -147 -6 -165
-19 -20 -23 -20 -46 1 -20 18 -25 112 -8 157 11 31 45 35 60 7z"/>
<path d="M4483 2939 c-100 -29 -122 -218 -34 -286 9 -7 38 -13 64 -13 41 0 53
5 78 29 23 24 29 38 29 73 -1 68 -43 105 -106 93 -25 -4 -34 -2 -34 8 0 25 36
49 70 45 26 -2 34 1 41 20 7 19 4 24 -17 32 -30 12 -48 11 -91 -1z m57 -199
c0 -27 -5 -43 -15 -46 -24 -10 -45 15 -45 52 0 31 3 34 30 34 28 0 30 -2 30
-40z"/>
<path d="M4693 2939 c-38 -11 -46 -22 -38 -49 6 -18 12 -20 56 -14 l49 7 0
-30 c0 -20 -12 -44 -35 -70 -48 -55 -85 -111 -85 -129 0 -12 18 -14 108 -12
l107 3 0 30 c0 29 -2 30 -48 33 l-47 3 34 42 c60 73 69 133 26 172 -23 21 -84
28 -127 14z"/>
<path d="M4870 2931 c0 -42 10 -51 61 -51 27 0 49 -4 49 -8 0 -4 -16 -54 -35
-111 -19 -56 -35 -107 -35 -112 0 -5 17 -9 38 -9 l37 0 48 140 c27 77 46 147
43 155 -4 12 -27 15 -106 15 -92 0 -100 -2 -100 -19z"/>
<path d="M5141 2936 c-40 -14 -46 -25 -28 -50 11 -15 19 -16 49 -8 19 5 41 6
48 2 18 -11 5 -53 -28 -92 -48 -56 -93 -128 -87 -138 3 -6 52 -10 111 -10
l104 0 0 34 c0 34 -1 35 -47 38 -43 3 -46 4 -30 19 78 78 90 171 26 204 -33
18 -71 18 -118 1z"/>
<path d="M5395 2941 c-50 -21 -73 -82 -51 -135 18 -43 48 -60 97 -53 44 6 43
7 27 -28 -9 -21 -18 -25 -53 -25 -36 0 -44 -4 -48 -22 -8 -28 7 -38 58 -38 84
0 135 61 135 162 0 113 -75 175 -165 139z m81 -87 c7 -39 -4 -58 -31 -52 -26
5 -39 31 -31 61 5 20 13 27 31 27 20 0 25 -6 31 -36z"/>
<path d="M5628 2938 c-28 -10 -33 -23 -22 -53 5 -13 15 -15 50 -9 35 5 44 3
44 -9 0 -8 -16 -22 -35 -31 -53 -25 -45 -62 15 -68 27 -2 36 -8 38 -25 5 -31
-30 -48 -70 -34 -17 6 -32 10 -33 9 -8 -11 -26 -50 -23 -52 13 -11 78 -26 110
-26 81 0 130 98 77 151 -21 21 -21 23 -5 49 22 38 20 57 -9 85 -26 27 -85 33
-137 13z"/>
<path d="M5875 2938 c-11 -6 -25 -19 -32 -27 -13 -17 -17 -81 -7 -118 8 -28
55 -47 99 -40 39 6 42 2 19 -31 -11 -16 -25 -22 -53 -22 -31 0 -40 -4 -44 -22
-9 -32 -1 -38 55 -38 91 0 138 55 138 160 0 72 -20 122 -56 139 -32 14 -93 14
-119 -1z m85 -69 c18 -32 4 -71 -23 -67 -35 5 -38 88 -3 88 9 0 20 -9 26 -21z"/>
<path d="M6984 2940 c-52 -21 -82 -158 -50 -233 36 -87 168 -90 212 -5 20 38
23 142 5 185 -22 53 -103 79 -167 53z m86 -69 c16 -32 13 -147 -6 -165 -21
-22 -31 -20 -50 9 -13 20 -16 40 -12 89 3 35 9 69 12 75 11 18 44 13 56 -8z"/>
<path d="M7240 2937 c-54 -27 -68 -120 -24 -163 21 -22 32 -25 70 -22 35 2 44
0 44 -13 0 -24 -20 -37 -65 -41 -35 -3 -40 -6 -40 -28 0 -23 4 -25 52 -28 45
-3 55 1 87 28 56 50 71 145 34 224 -22 50 -102 72 -158 43z m85 -92 c0 -31 -4
-41 -19 -43 -22 -5 -36 14 -36 49 0 32 8 41 35 37 16 -2 20 -10 20 -43z"/>
<path d="M7512 2930 c-41 -25 -62 -73 -62 -140 0 -94 42 -150 114 -150 76 0
121 47 129 135 13 134 -82 215 -181 155z m88 -59 c17 -32 13 -147 -6 -166 -9
-9 -21 -14 -28 -12 -32 10 -48 115 -26 171 11 31 45 35 60 7z"/>
<path d="M7781 2932 c-25 -46 -71 -175 -71 -197 0 -24 3 -25 60 -25 l60 0 0
-36 c0 -35 1 -35 38 -32 33 3 37 6 40 33 2 16 10 34 17 40 7 5 15 21 18 34 4
17 1 24 -14 28 -14 4 -19 14 -19 40 0 33 -1 34 -37 31 -35 -3 -38 -6 -41 -35
-2 -21 -9 -33 -18 -33 -18 0 -14 16 22 99 13 29 21 57 18 62 -10 17 -63 10
-73 -9z"/>
<path d="M8049 2939 c-41 -15 -69 -76 -69 -148 0 -101 39 -151 116 -151 75 0
124 62 124 155 0 53 -18 102 -48 128 -24 22 -87 30 -123 16z m82 -79 c27 -96
-14 -209 -56 -154 -19 25 -21 170 -2 178 26 11 51 0 58 -24z"/>
<path d="M8293 2939 c-39 -11 -47 -23 -33 -49 9 -17 17 -19 51 -14 76 13 72
-25 -15 -133 -82 -100 -79 -104 52 -101 l107 3 0 30 c0 29 -2 30 -49 35 -46 5
-48 6 -31 20 29 23 75 101 75 126 0 75 -65 109 -157 83z"/>
<path d="M8565 2940 c-58 -23 -91 -112 -75 -200 23 -127 190 -137 231 -13 14
43 7 141 -12 171 -26 42 -96 62 -144 42z m78 -90 c12 -45 2 -141 -15 -151 -18
-12 -24 -11 -42 7 -12 11 -16 36 -16 93 0 43 3 81 7 85 4 4 18 6 32 4 19 -2
27 -11 34 -38z"/>
<path d="M8729 2945 c-3 -3 -4 -18 -1 -35 4 -29 6 -30 58 -30 30 0 54 -2 54
-5 0 -2 -18 -55 -40 -116 -22 -62 -40 -114 -40 -116 0 -2 18 -3 39 -3 28 0 41
5 46 18 39 98 86 247 83 265 -3 21 -8 22 -98 25 -53 1 -98 0 -101 -3z"/>
<path d="M9020 2939 c-48 -19 -60 -39 -60 -95 0 -45 4 -55 28 -74 21 -17 36
-21 70 -17 45 5 52 -4 28 -35 -9 -12 -27 -18 -53 -18 -33 0 -42 -4 -46 -22 -9
-34 2 -40 66 -36 56 4 63 8 94 43 30 35 33 44 33 107 0 80 -17 121 -60 143
-33 17 -64 18 -100 4z m68 -61 c17 -17 15 -65 -4 -72 -25 -10 -33 -7 -44 14
-24 44 15 91 48 58z"/>
<path d="M9202 2918 c3 -32 4 -33 56 -36 28 -2 52 -6 52 -11 0 -4 -16 -54 -35
-111 -19 -57 -35 -108 -35 -112 0 -4 17 -8 38 -8 l37 0 49 140 c27 77 46 146
42 155 -4 12 -25 15 -106 15 l-101 0 3 -32z"/>
<path d="M9493 2931 c-63 -29 -89 -95 -72 -185 12 -70 52 -106 115 -106 64 0
104 40 104 102 -1 68 -43 105 -106 93 -25 -4 -34 -2 -34 8 0 26 37 50 68 44
24 -5 31 -2 40 18 13 28 10 31 -38 39 -24 4 -48 0 -77 -13z m68 -172 c8 -16 8
-28 0 -45 -20 -45 -61 -18 -61 40 0 21 5 26 25 26 15 0 29 -8 36 -21z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
